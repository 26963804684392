import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";

import { fadeInOut } from "../../services/animations";
import { BootstrapTabDirective } from "../../directives/bootstrap-tab.directive";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Permission } from "../../models/permission.model";

@Component({
  animations: [fadeInOut],
  selector: "settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy {
  public isProfileActivated = true;
  public isPreferencesActivated = false;

  public fragmentSubscription: any;

  public readonly profileTab = "profile";
  public readonly preferencesTab = "preferences";

  @ViewChild("tab", { static: true })
  public tab: BootstrapTabDirective;

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService, private authService: AuthService) {
  }

  public ngOnInit() {
    this.fragmentSubscription = this.route.fragment.subscribe((anchor) => this.showContent(anchor));
  }

  public ngOnDestroy() {
    this.fragmentSubscription.unsubscribe();
  }

  public handleLanguageChangedEvent() {
  }

  public showContent(anchor: string) {
    if (anchor) {
      anchor = anchor.toLowerCase();
    }

    if ((this.isFragmentEquals(anchor, this.profileTab) && !this.canViewUsers) ||
      (this.isFragmentEquals(anchor, this.preferencesTab))) {
      return;
    }

    this.tab.show(`#${anchor || this.profileTab}Tab`);
  }

  public onShowTab(event) {
    const activeTab = event.target.hash.split("#", 2).pop();

    this.isProfileActivated = activeTab === this.profileTab;
    this.isPreferencesActivated = activeTab === this.preferencesTab;

    this.router.navigate([], { fragment: activeTab });
  }

  private isFragmentEquals(fragment1: string, fragment2: string) {
    if (fragment1 == null) {
      fragment1 = "";
    }

    if (fragment2 == null) {
      fragment2 = "";
    }

    return fragment1.toLowerCase() === fragment2.toLowerCase();
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewGroups() {
    return this.accountService.userHasPermission(Permission.viewGroupsPermission);
  }

  get canViewCircles() {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }
}
