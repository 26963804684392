<div>
  <form *ngIf="formResetToggle" [attr.autocomplete]="isGeneralEditor ? 'NaN' : null" name="userInfoForm" #f="ngForm"
    novalidate
    (ngSubmit)="f.form.valid ? save() :

          (!userName.valid && showErrorAlert('A felhasználó név kötelező adat.', 'Kérjük, hogy adja meg az adatot!'));

          (userPassword && !userPassword.valid && showErrorAlert('A jelszó megadása kötelező.', 'Kérjük, hogy adja meg a jelszót!'));

          (email.errors?.required && showErrorAlert('Az email cím kötelező adat.', 'Kérjük, hogy adjon meg egy érvényes email címet!'));
          (email.errors?.pattern && showErrorAlert('Hibás email cím.', 'Kérjük adjon meg egy érvényes email címet!'));

          (isChangePassword && isEditingSelf && !currentPassword.valid && showErrorAlert('A jelszó megadása kötelező.', 'Adja meg a jelszót!'));

          (isChangePassword && !newPassword.valid && showErrorAlert('Az új jelszó megadása kötelező.', 'Adja meg a jelszót!'));

          (isChangePassword && confirmPassword.errors?.required && showErrorAlert('A jelszó megerősítése kötelező.', 'Adja meg a jelszót!'));
          (isChangePassword && confirmPassword.errors?.validateEqual && showErrorAlert('A jelszó megerősítés nem egyezik.', 'Az új jelszót meg kell erősítenie!'));

          (canAssignGroups && !groups.valid && showErrorAlert('A csoport megadása kötelező', 'Kérjük, hogy válasszon legalább egy csoportot!'));">

    <div class="form-group row">
      <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label"
        for="jobTitle-{{uniqueId}}">{{'users.editor.JobTitle' | translate}}</label>
      <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <p class="form-control-plaintext">{{user.jobTitle}}</p>
      </div>
      <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <input autofocus type="text" attr.id="jobTitle-{{uniqueId}}" name="jobTitle"
          placeholder="Ide írhatja be a felhasználó munkakörét." class="form-control" [(ngModel)]="userEdit.jobTitle" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
      </div>
    </div>

    <div class="form-group row has-feedback-icon">
      <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label"
        for="userName-{{uniqueId}}">{{'users.editor.UserName' | translate}}</label>
      <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <p class="form-control-plaintext">{{user.userName}}</p>
      </div>
      <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <input type="text" attr.id="userName-{{uniqueId}}" name="userName"
          [attr.autocomplete]="isGeneralEditor ? 'new-password' : null"
          placeholder="A felhasználó nevének megadása kötelező. A név legalább 2, de legfeljebb 200 karakterből áll."
          class="form-control" [(ngModel)]="userEdit.userName" #userName="ngModel" required minlength="2"
          maxlength="200" />
        <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': userName.valid, 'fa-times' : !userName.valid}" aria-hidden="true"></i>
        <span *ngIf="showValidationErrors && f.submitted && !userName.valid" class="invalid-feedback">
          {{'users.editor.UserNameRequired' | translate}}
        </span>
      </div>
    </div>

    <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName" class="row">
      <div class="col-lg-12">
        <hr class="password-separator-hr" />
      </div>
    </div>

    <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName"
      class="form-group row has-feedback-icon">
      <label class="col-form-label col-lg-2"
        for="userPassword-{{uniqueId}}">{{'users.editor.Password' | translate}}</label>
      <div class="col-lg-10">
        <input type="password" attr.id="userPassword-{{uniqueId}}" name="userPassword"
          [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" placeholder="A jelszó megadása kötelező."
          class="form-control"
          [ngClass]="{'is-valid': f.submitted && userPassword.valid, 'is-invalid' : f.submitted && !userPassword.valid}"
          [(ngModel)]="userEdit.currentPassword" #userPassword="ngModel" required />
        <small class="hint-sm">{{'users.editor.PasswordHint' | translate}}</small>
        <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': userPassword.valid, 'fa-times' : !userPassword.valid}" aria-hidden="true"></i>
        <span *ngIf="showValidationErrors && f.submitted && !userPassword.valid" class="invalid-feedback">
          {{'users.editor.CurrentPasswordRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
      </div>
    </div>

    <div class="form-group row has-feedback-icon">
      <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label"
        for="email-{{uniqueId}}">{{'users.editor.Email' | translate}}</label>
      <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <p class="form-control-plaintext">{{user.email}}</p>
      </div>
      <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <input type="text" attr.id="email-{{uniqueId}}" name="email" placeholder="Az email cím megadása kötelező."
          class="form-control"
          [ngClass]="{'is-valid': f.submitted && email.valid, 'is-invalid' : f.submitted && !email.valid}"
          [(ngModel)]="userEdit.email" #email="ngModel" required maxlength="200"
          pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />
        <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': email.valid, 'fa-times' : !email.valid}" aria-hidden="true"></i>
        <span *ngIf="showValidationErrors && f.submitted && email.errors?.required" class="invalid-feedback">
          {{'users.editor.EmailRequired' | translate}}
        </span>
        <span *ngIf="showValidationErrors && f.submitted && email.errors?.pattern" class="invalid-feedback">
          {{'users.editor.InvalidEmail' | translate}}
        </span>
      </div>
    </div>

    <div *ngIf="isEditMode" class="row">
      <div class="col-lg-12">
        <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
      </div>
    </div>

    <div *ngIf="isEditMode" class="form-group row">
      <label for="newPassword-{{uniqueId}}"
        class="col-form-label col-lg-2">{{'users.editor.NewPassword' | translate}}</label>
      <div *ngIf="!isChangePassword && !isNewUser" class="col-lg-10">
        <button type="button" (click)="changePassword()"
          class="btn btn-link">{{'users.editor.ChangePassword' | translate}}</button>
      </div>

      <div *ngIf="isChangePassword || isNewUser" class="col-lg-10">
        <div class="password-well card card-body bg-light">
          <div *ngIf="isEditingSelf" class="form-group row has-feedback-icon">          
            <label class="col-form-label col-lg-3"
              for="currentPassword-{{uniqueId}}">{{'users.editor.CurrentPassword' | translate}}</label>
            <div class="col-lg-9">
              <input type="password" attr.id="currentPassword-{{uniqueId}}" name="currentPassword"
                [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" placeholder="Adja meg a jelszót!"
                class="form-control"
                [ngClass]="{'is-valid': f.submitted && currentPassword.valid, 'is-invalid' : f.submitted && !currentPassword.valid}"
                [(ngModel)]="userEdit.currentPassword" #currentPassword="ngModel" required />
              <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
                [ngClass]="{'fa-check': currentPassword.valid, 'fa-times' : !currentPassword.valid}"
                aria-hidden="true"></i>
              <span *ngIf="showValidationErrors && f.submitted && !currentPassword.valid" class="invalid-feedback">
                {{'users.editor.CurrentPasswordRequired' | translate}}
              </span>
            </div>
          </div>

          <div *ngIf="!isNewUser" class="row">
            <div class="col-lg-12">
              <hr class="password-separator-hr" />
            </div>
          </div>

          <div class="form-group row has-feedback-icon">
            <label class="col-form-label col-lg-3"
              for="newPassword-{{uniqueId}}">{{'users.editor.NewPassword' | translate}}</label>
            <div class="col-lg-9">
              <input type=" password" attr.id="newPassword-{{uniqueId}}" name="newPassword"
                [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" placeholder="Adja meg az új jelszót!"
                tooltip="Jelszó pattern: Legalább 6 kerakter hosszú legyen, tartalmazzon legalább 1 számot, 1 kisbetüt és 1 nagybetüt."
                class="form-control"
                [ngClass]="{'is-valid': f.submitted && newPassword.valid, 'is-invalid' : f.submitted && !newPassword.valid}"
                [(ngModel)]=" userEdit.newPassword" #newPassword="ngModel" required minlength="6"
                validateEqual="confirmPassword" reverse="true" />
              <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
                [ngClass]="{'fa-check': newPassword.valid, 'fa-times' : !newPassword.valid}" aria-hidden="true"></i>
              <span *ngIf="showValidationErrors && f.submitted && !newPassword.valid" class="invalid-feedback">
                {{'users.editor.NewPasswordRequired' | translate}}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <hr class="password-separator-hr" />
            </div>
          </div>

          <div class="form-group row has-feedback-icon">
            <label class="col-form-label col-lg-3"
              for="confirmPassword-{{uniqueId}}">{{'users.editor.ConfirmPassword' | translate}}</label>
            <div class="col-lg-9">
              <input type="password" attr.id="confirmPassword-{{uniqueId}}" name="confirmPassword"
                [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" placeholder="Erősítse meg az új jelszót!"
                class="form-control"
                [ngClass]="{'is-valid': f.submitted && confirmPassword.valid, 'is-invalid' : f.submitted && !confirmPassword.valid}"
                [(ngModel)]="userEdit.confirmPassword" #confirmPassword="ngModel" required validateEqual="password" />
              <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
                [ngClass]="{'fa-check': confirmPassword.valid, 'fa-times' : !confirmPassword.valid}"
                aria-hidden="true"></i>
              <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.required"
                class="invalid-feedback">
                {{'users.editor.ConfirmationPasswordRequired' | translate}}
              </span>
              <span
                *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.validateEqual && !confirmPassword.errors?.required"
                class="invalid-feedback">
                {{'users.editor.PasswordMismatch' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--******************************************************************************************************-->

    <div *ngIf="!isEditMode || canAssignGroups" class="row">
      <div class="col-lg-12">
        <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
      </div>
    </div>

    <div *ngIf="!isEditMode || canAssignGroups" class="form-group row has-feedback-icon">
      <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label"
        for="groups-user-info">{{'users.editor.Groups' | translate}}</label>
      <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <p class="form-control-plaintext">
          <span *ngFor="let group of user.groups">
            <span title='{{getGroupByName(group)?.description}}'
              class="badge badge-pill badge-secondary">{{group}}</span>
          </span>
        </p>
      </div>
      <div *ngIf="isEditMode && canAssignGroups"
        [ngClass]="{'is-valid': f.submitted && groups.valid, 'is-invalid' : f.submitted && !groups.valid}"
        [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <select id="groups-user-info" name="groups" [(ngModel)]="userEdit.groups" #groups="ngModel"
          #groupsSelector="bootstrap-select" class="selectpicker form-control left-status" bootstrapSelect required
          multiple data-live-search="true" data-actions-box="false" data-live-search-placeholder="Keresés..."
          title="Csoportok kiválasztása">
          <option *ngFor="let group of allGroups"
            attr.data-content="<span title='{{group.description}}' class='badge'>{{group.name}}</span>"
            attr.value="{{group.name}}">
            {{group.name}}
          </option>
        </select>
        <i *ngIf="showValidationErrors && f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': groups.valid, 'fa-times' : !groups.valid}" aria-hidden="true"></i>
        <span *ngIf="showValidationErrors && f.submitted && !groups.valid" class="invalid-feedback">
          {{'users.editor.GroupRequired' | translate}}
        </span>
      </div>
    </div>

    <!--******************************************************************************************************-->

    <div class="row">
      <div class="col-lg-12">
        <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
      </div>
    </div>

    <div class="form-group row">
      <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label"
        for="fullName-{{uniqueId}}">{{'users.editor.FullName' | translate}}</label>
      <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <p class="form-control-plaintext">{{user.fullName}}</p>
      </div>
      <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <input type="text" id="userEdit-lastName" name="lastName" placeholder="A vezetéknév megadása kötelező."
          class="form-control"
          [ngClass]="{'is-valid': f.submitted && lastName.valid, 'is-invalid' : f.submitted && !lastName.valid}"
          [(ngModel)]="userEdit.lastName" #lastName="ngModel" required />
        <i *ngIf="f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': lastName.valid, 'fa-times' : !lastName.valid}" aria-hidden="true"></i>
        <span class="invalid-feedback" *ngIf="f.submitted && !lastName.valid">
          Vezetéknév megadása kötelező!
        </span>
        <input type="text" id="sign-up-firstName" name="firstName" placeholder="A keresztnév megadása kötelező."
          class="form-control"
          [ngClass]="{'is-valid': f.submitted && firstName.valid, 'is-invalid' : f.submitted && !firstName.valid}"
          [(ngModel)]="userEdit.firstName" #firstName="ngModel" required />
        <i *ngIf="f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': firstName.valid, 'fa-times' : !firstName.valid}" aria-hidden="true"></i>
        <span class="invalid-feedback" *ngIf="f.submitted && !firstName.valid">
          Keresztnév megadása kötelező!
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
      </div>
    </div>

    <div class="form-group row">
      <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label"
        for="phoneNumber-{{uniqueId}}">{{'users.editor.PhoneNumber' | translate}}</label>
      <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <p class="form-control-plaintext">{{user.phoneNumber}}</p>
      </div>
      <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
        <input type="text" attr.id="phoneNumber-{{uniqueId}}" name="phoneNumber" placeholder="Telefonszám"
          class="form-control" [(ngModel)]="userEdit.phoneNumber" />
      </div>
    </div>

    <div *ngIf="!isViewOnly" class="row">
      <div class="col-lg-12">
        <hr [class.last-separator-hr]="!isEditMode" [class.edit-last-separator-hr]="isEditMode" />
      </div>
    </div>

    <div class="row" *ngIf="isEditMode && canAssignCompanies && !router.isActive('/settings', false)" class="row">
      <div class="col-sm-12 card card-body bg-light companiesRow">
        <span>{{'users.editor.CompanyList' | translate}}</span>
        <custom-dual-list [source]="allCompanies" [(destination)]="selectedCompanies" [key]="dualListBoxKeyName"
          [display]="dualListBoxDisplayName" [format]="format" height="285px" [filter]="true"></custom-dual-list>
      </div>
    </div>

    <div *ngIf="!isViewOnly" class="row">
      <div class="col-lg-12">
        <hr [class.last-separator-hr]="!isEditMode" [class.edit-last-separator-hr]="isEditMode" />
      </div>
    </div>

    <div *ngIf="!isViewOnly" class="form-group row">
      <div class="col-sm-5">
        <div *ngIf="isGeneralEditor && isEditMode" class="float-left">
          <div class="checkbox user-administrator">
            <label>
              <input type="checkbox" name="isAdministrator" [(ngModel)]="userEdit.isAdministrator">
              {{'users.editor.Administrator' | translate}}
            </label>
          </div>
          <button *ngIf="!userEdit.isLockedOut && !isNewUser" type="button" (click)="lockUser()"
            class="btn btn-danger block-user" [disabled]="isSaving">
            <i class='fa fa-lock-alt'></i> {{'users.editor.Block' | translate}}</button>
          <button *ngIf="userEdit.isLockedOut" type="button" (click)="unlockUser()" class="btn btn-warning unblock-user"
            [disabled]="isSaving">
            <i class='fa fa-unlock-alt'></i> {{'users.editor.Unblock' | translate}}</button>
        </div>
      </div>
      <div class="col-sm-7">
        <div ngPreserveWhitespaces class="float-right">
          <button *ngIf="!isEditMode && isGeneralEditor" type="button" (click)="close()"
            class="btn btn-outline-secondary"><i class='fa fa-close'></i> {{'users.editor.Close' | translate}}</button>
          <button *ngIf="!isEditMode && !isGeneralEditor" type="button" (click)="edit()"
            class="btn btn-outline-secondary"><i class='fa fa-edit'></i> {{'users.editor.Edit' | translate}}</button>
          <button *ngIf="isEditMode" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i
              class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
          <button *ngIf="isEditMode" type="submit" class="btn btn-primary" [disabled]="isSaving">
            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i>
            {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>