import { Component } from "@angular/core";
import { fadeInOut } from "../../services/animations";

@Component({
    animations: [fadeInOut],
    selector: "contact",
    styleUrls: ["./contact.component.scss"],
    templateUrl: "./contact.component.html",
})

export class ContactComponent {
}