export type PermissionNames =
  "View Users" | "Manage Users" | "View NavStatus" |
  "View Groups" | "Manage Groups" | "Assign Groups" | "SuperUser" | "Administrator";

export type PermissionValues =
  "users.view" | "users.manage" | "navStatus.view" |
  "groups.view" | "groups.manage" | "groups.assign" | "SuperUser" | "Administrator";

export class Permission {
  public static readonly superUsePermission: string[] = ["navStatus.view", "users.view", "users.manage", "groups.view", "groups.manage", "groups.assign", "SuperUser", "Administrator"];
  public static readonly administratorPermission: string[] = ["navStatus.view", "users.view", "users.manage", "groups.view", "groups.manage", "groups.assign", "Administrator"];

  public static readonly viewUsersPermission: PermissionValues = "users.view";
  public static readonly manageUsersPermission: PermissionValues = "users.manage";
  public static readonly viewNavStatusPermission: PermissionValues = "navStatus.view";

  public static readonly viewGroupsPermission: PermissionValues = "groups.view";
  public static readonly manageGroupsPermission: PermissionValues = "groups.manage";
  public static readonly assignGroupsPermission: PermissionValues = "groups.assign";

  constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.description = description;
  }

  public name: PermissionNames;
  public value: PermissionValues;
  public groupName: string;
  public description: string;
}
