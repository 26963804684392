export class CompanyAddress {
    constructor(id?: number, partnerId?: number,
                cegId?: number, megnevezes?: string,
                orszagKod?: string, iranyitoSzam?: string,
                telepules?: string, kerulet?: string,
                kozteruletNeve?: string, kozteruletJellege?: string,
                hazszam?: string, epulet?: string,
                lepcsohaz?: string, szint?: string,
                ajto?: string
    ) {
        this.id = id;
        this.partnerId = partnerId;
        this.cegId = cegId;
        this.megnevezes = megnevezes;
        this.orszagKod = orszagKod;
        this.iranyitoSzam = iranyitoSzam;
        this.telepules = telepules;
        this.kozteruletNeve = kozteruletNeve;
        this.kozteruletJellege = kozteruletJellege;
        this.hazszam = hazszam;
        this.epulet = epulet;
        this.lepcsohaz = lepcsohaz;
        this.szint = szint;
        this.ajto = ajto;
    }

    public id: number;
    public partnerId: number;
    public cegId: number;
    public megnevezes: string;
    public epulet: string;
    public lepcsohaz: string;
    public szint: string;
    public ajto: string;
    public orszagKod: string;
    public iranyitoSzam: string;
    public telepules: string;
    public kozteruletNeve: string;
    public kozteruletJellege: string;
    public hazszam: string;
    public kodAktEv: string;
    public circleName: string;
}