<div class="container">
  <header class="pageHeader">
    <h3>
      <i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.AccountingFeatures' | translate}}</h3>
  </header>

  <div [@fadeInOut]>
    <div class="row">
      <div class="col-md-11 offset-md-1">
        <p>
          Rugalmasan alakítható kettős könyvviteli modulunk egyszerűbb és komplexebb számvitelű cégek könyvelésére is alkalmas. A számlarend
          teljesen szabadon kialakítható. A könyvelés történhet elsődleges költségnem vagy költséghely elszámolásban
          is. A külső rendszerekből érkező adatok formátumának megfelelően az import széles körben paraméterezhető,
          így a könyvelésbe be lehet olvasni teljesen más szoftverek standard feladásait is (pl. kimenő számlák adatai, bérfeladások stb.).
          Saját ügyviteli szoftvereink (készlet, számlázás, bér) feladásait is egyszerűen importálni lehet.
          Az export funkció segítségével a lekérdezések összes adata kimásolható standard csv formátumban.
        </p>
        <p>
          A standard funkciók mellett olyan szolgáltatásokkal bővítettük ki az alkalmazást, amellyel hatékonyabbá és gyorsabbá tudja
          tenni cégének vagy cégeinek könyvelését.
        </p>
        <p> Amennyiben a modul egyes szolgáltatásai bővebben érdeklik, tájékozódjon a részletekről a
          <a href="javascript:;" routerLink="/features/accounting-manual">felhasználói útmutatóból</a> !
        </p>
        <h5>
          <b>A kettős könyvviteli modul szolgáltatásai</b>
        </h5>
        <ul style="list-style-type:disc">
          <li>
            könyvvitelhez kapcsolódó alaptörzsek:
            <ul>
              <li>teljesen szabadon kialakítható számlatükör maximum 8 karakter hosszú számlaszámokkal,</li>
              <li>vevők, szállítók és egyéb partnerek nyilvántartása,</li>
              <li>teljesen szabadon paraméterezhető áfakulcs nyilvántartás,</li>
              <li>projektek és járművek nyilvántartása és terhelése költség objektumként.</li>
            </ul>
          </li>
          <li>
            könyvelés:
            <ul>
              <li>
                bank, pénztár, vegyes, vevő és szállító napló szerinti könyvelés könnyen átlátható ("akasztófás") formában, kényelmi szolgáltatásokkal
                kibővítve,
              </li>
              <li>
                adatok fogadása külső rendszerekből - a program standard csv vagy txt állományokból tud adatot fogadni, az importálandó adatok
                formátuma kötetlen, a beolvasandó információt tartalmazó oszlopok szabadon rendelhetőek a mezőkhöz,
              </li>
              <li>
                banki rendszerből letöltött adatok automatikus beolvasása - a rendszer "megtanulja" a banki tételekhez kapcsolódó kontírozást
                és a banki közleményekből a folyószámla kiegyenlítéseket is képes elvégezni.
              </li>
            </ul>
          </li>
          <li>
            lekérdezések és listák:
            <ul>
              <li>
                számlaforgalmak, naplók lekérdezése,
              </li>
              <li>
                főkönyvi kivonat és mérleg nyomtatványok,
              </li>
              <li>
                folyószámla analitikus nyilvántartás, lejárt tételek korosító listája, egyenlegközlő és felszólító levelek készítése
              </li>
              <li>
                költségfelosztások, költségfelosztó ívek, lekérdezések.
              </li>
            </ul>
          </li>
          <li>
            ÁFA nyilvántartás:
            <ul>
              <li>
                pénzforgalmi ÁFA nyilvántartás vezetése technikai számlák segítségével (3681, 4681): az ÁFA az elszámolási számlákon keresztül
                kerül könyvelésre és a kiegyenlítésekkor a program automatikusan könyveli a teljesítésbe ment
                adót;
              </li>
              <li>
                áfa lista készítése könyvelési dátum vagy számlához egyedileg kapcsolt teljesítési időpont alapján, illetve pénzforgalmi
                elszámolás szerint;
              </li>
              <li>
                határérték feletti tételek analitikája bevalláshoz, az adószámonkénti gyűjtéshez a bank, pénztár és vegyes naplón vezetett
                könyvelésekhez is kapcsolható ÁFA partner;
              </li>
              <li>
                közösségi értékesítés analitikája adószámonkénti bontásban;
              </li>
              <li>
                minden adat átadható XML formátumban a NAV bevalláskitöltő rendszerének.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          A könyeléshez kapcsolódó kiegészítő moduljainkkal vezetheti analitikus nyilvántartásait.
        </p>
        <p>
          Ha ki szeretné próbálni alkalmazásunkat, akkor
          <a href="javascript:;" routerLink="/signup">regisztráljon</a> rendszerünkbe!
        </p>
      </div>
    </div>
  </div>
</div>
