import { Role } from './role.model';

export class Group {
    constructor(name?: string, description?: string, roles?: Role[]) {
        this.name = name;
        this.description = description;
        this.roles = roles;
    }

    public id: number;
    public name: string;
    public description: string;
    public usersCount: string;
    public circleName: string;
    public roles: Role[];
}
