<div>
  <div class="row control-box">
    <div class="col-lg-8">
      <div class="form-group search-box">
        <search-box (searchChange)="onSearchChanged($event)" placeholder="{{'groups.management.Search' | translate}}">>
        </search-box>
      </div>
    </div>
    <div class="col-lg-4 pr-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li *ngIf="canManageGroups" class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" (click)="newGroup()">
            <i class="fa fa-plus-circle"></i> {{'groups.management.NewGroup' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table table-striped table-hover" [loadingIndicator]="loadingIndicator" [rows]="rows"
    [rowHeight]="35" [columnMode]="'force'" [headerHeight]="35" [footerHeight]="35" [columns]="columns" [scrollbarV]="true"
    [sorts]="[{prop: 'name', dir: 'asc'}]">
  </ngx-datatable>

  <ng-template #userForSuperAdministratorTemplate let-row="row" let-value="value">
    <span *ngIf="canViewCircles" class="group-circle badge">
      {{value}}
    </span>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <a *ngIf="canManageGroups" class="btn btn-link btn-sm" href="javascript:;" (click)="editGroup(row)"><i class="fa fa-pencil-square-o"
        aria-hidden="true"></i> {{'groups.management.Edit' | translate}}</a>
    <a *ngIf="!canManageGroups" class="btn btn-link btn-sm" href="javascript:;" (click)="editGroup(row)"><i class="fa fa-eye" aria-hidden="true"></i>
      {{'groups.management.Details' | translate}}</a>
    {{canManageGroups ? '|' : ''}}
    <a *ngIf="canManageGroups" class="btn btn-link btn-sm" href="javascript:;" (click)="deleteGroup(row)"><i class="fa fa-trash-o"
        aria-hidden="true"></i> {{'groups.management.Delete' | translate}}</a>
  </ng-template>

  <div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 *ngIf="!canManageGroups" class="modal-title float-left"><i class="fa fa-shield"></i>
            {{'groups.management.GroupDetails' | translate}} "{{editingGroupName}}"</h4>
          <h4 *ngIf="canManageGroups" class="modal-title float-left"><i class="fa fa-shield"></i>
            {{editingGroupName ? ('groups.management.EditGroup' | translate:editingGroupName) : ('groups.management.NewGroup' | translate)}}
          </h4>
          <button type="button" class="close float-right" title="Close" (click)="editorModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <group-editor #groupEditor></group-editor>
        </div>
      </div>
    </div>
  </div>
</div>
