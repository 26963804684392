import { Component } from "@angular/core";
import { fadeInOut } from "../../services/animations";

@Component({
    selector: "features",
    templateUrl: "./features.component.html",
    styleUrls: ["./features.component.scss"],
    animations: [fadeInOut],
})
export class FeaturesComponent {
}