export class Circle {
    constructor(name?: string, description?: string, userIds?: number[], companyIds?: number[]) {
        this.name = name;
        this.description = description;
        this.userIds = userIds || [];
        this.companyIds = companyIds || [];
    }

    public id: number;
    public name: string;
    public description: string;
    public userIds: number[] = [];
    public companyIds: number[] = [];
}