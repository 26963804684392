import { Component } from "@angular/core";
import { fadeInOut } from "../../../services/animations";

@Component({
    selector: "accounting-features",
    templateUrl: "./accounting-features.component.html",
    styleUrls: ["./accounting-features.component.scss"],
    animations: [fadeInOut],
})
export class AccountingFeaturesComponent {
}