<div>
  <form *ngIf="formResetToggle" class="form-horizontal" name="navOnlineInfoForm" #f="ngForm" novalidate (ngSubmit)="f.form.valid ? save() :
  (notificationEmailAddress.errors?.pattern && showErrorAlert('Hibás email cím.', 'Kérjük adjon meg egy érvényes email címet!'));
  (dailyNotificationEmailAddress.errors?.pattern && showErrorAlert('Hibás email cím.', 'Kérjük adjon meg egy érvényes email címet!'));
  ">

    <div>
      <div class="form-group row">
        <label class="col-form-label col-lg-3" for="navUserName-{{uniqueId}}">{{'navOnline.editor.NavUserName' | translate}}</label>
        <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && navUserName.valid, 'is-invalid' : f.submitted && !navUserName.valid}">
          <input autofocus type="text" attr.id="navUserName-{{uniqueId}}" name="navUserName"
            placeholder="Itt a NAV online számla felületen létrehozott technikai felhasználó nevét adja meg!" class="form-control"
            [(ngModel)]="navOnline.navUserName" #navUserName="ngModel" required />
          <span *ngIf="f.submitted" class="fa form-control-feedback"
            [ngClass]="{'fa-check': navUserName.valid, 'fa-times' : !navUserName.valid}"></span>
          <span *ngIf="f.submitted && !navUserName.valid" class="invalid-feedback">
            {{'navOnline.editor.NavUserNameRequired' | translate}}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-lg-3" for="xmlSignKey-{{uniqueId}}">{{'navOnline.editor.XmlSignKey' | translate}}</label>
        <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && xmlSignKey.valid, 'is-invalid' : f.submitted && !xmlSignKey.valid}">
          <input type="text" attr.id="xmlSignKey-{{uniqueId}}" name="xmlSignKey" placeholder="A NAV technikai felhasználó XML aláírókulcsa."
            class="form-control" [(ngModel)]="navOnline.xmlSignKey" #xmlSignKey="ngModel" required />
          <span *ngIf="f.submitted" class="fa form-control-feedback"
            [ngClass]="{'fa-check': xmlSignKey.valid, 'fa-times' : !xmlSignKey.valid}"></span>
          <span *ngIf="f.submitted && !xmlSignKey.valid" class="invalid-feedback">
            {{'navOnline.editor.XmlSignKeyRequired' | translate}}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-lg-3" for="exchangeKey-{{uniqueId}}">{{'navOnline.editor.ExchangeKey' | translate}}</label>
        <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && exchangeKey.valid, 'is-invalid' : f.submitted && !exchangeKey.valid}">
          <input type="text" attr.id="exchangeKey-{{uniqueId}}" name="exchangeKey" placeholder="A NAV technikai felhasználó cserekulcsa."
            class="form-control" [(ngModel)]="navOnline.exchangeKey" #exchangeKey="ngModel" required />
          <span *ngIf="f.submitted" class="fa form-control-feedback"
            [ngClass]="{'fa-check': exchangeKey.valid, 'fa-times' : !exchangeKey.valid}"></span>
          <span *ngIf="f.submitted && !exchangeKey.valid" class="invalid-feedback">
            {{'navOnline.editor.ExchangeKeyRequired' | translate}}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-lg-3" for="password-{{uniqueId}}">{{'navOnline.editor.Password' | translate}}</label>
        <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && password.valid, 'is-invalid' : f.submitted && !password.valid}">
          <input type="text" attr.id="password-{{uniqueId}}" name="password"
            placeholder="A NAV technikai felhasználó online-számla felületen megadott jelszava." class="form-control" [(ngModel)]="navOnline.password"
            #password="ngModel" required />
          <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': password.valid, 'fa-times' : !password.valid}"></span>
          <span *ngIf="f.submitted && !password.valid" class="invalid-feedback">
            {{'navOnline.editor.PasswordRequired' | translate}}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-lg-3" for="notificationEmailAddress-{{uniqueId}}">
          <input type="checkbox" name="immediateEmailNotification" [(ngModel)]="navOnline.immediateEmailNotification" (change)="onCheckboxChangeForImmediate($event)">
          {{'navOnline.editor.NotificationEmailAddress' | translate}}</label>
        <div class="col-lg-9"
          [ngClass]="{'is-valid': f.submitted && notificationEmailAddress.valid, 'is-invalid' : f.submitted && !notificationEmailAddress.valid}">
          <input type="text" attr.id="notificationEmailAddress-{{uniqueId}}" name="notificationEmailAddress"
            placeholder="Azonnal értesítendő címzettek felsorolása ; elválasztva." class="form-control"
            [(ngModel)]="navOnline.notificationEmailAddress" #notificationEmailAddress="ngModel" maxlength="500"
            pattern="^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$" />
          <span *ngIf="f.submitted" class="fa form-control-feedback"
            [ngClass]="{'fa-check': notificationEmailAddress.valid, 'fa-times' : !notificationEmailAddress.valid}"></span>
          <span *ngIf="f.submitted && !notificationEmailAddress.valid" class="invalid-feedback">
            {{'navOnline.editor.NotificationEmailAddress' | translate}}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-lg-3" for="dailyNotificationEmailAddress-{{uniqueId}}">
          <input type="checkbox" name="dailyEmailNotification" [(ngModel)]="navOnline.dailyEmailNotification" (change)="onCheckboxChangeForDaily($event)">
          {{'navOnline.editor.DailyNotificationEmailAddress' | translate}}</label>
        <div class="col-lg-9"
          [ngClass]="{'is-valid': f.submitted && dailyNotificationEmailAddress.valid, 'is-invalid' : f.submitted && !dailyNotificationEmailAddress.valid}">
          <input type="text" attr.id="dailyNotificationEmailAddress-{{uniqueId}}" name="dailyNotificationEmailAddress"
            placeholder="Naponta egyszer értesítendő címzettek felsorolása ; elválasztva." class="form-control"
            [(ngModel)]="navOnline.dailyNotificationEmailAddress" #dailyNotificationEmailAddress="ngModel" maxlength="500"
            pattern="^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$" />
          <span *ngIf="f.submitted" class="fa form-control-feedback"
            [ngClass]="{'fa-check': dailyNotificationEmailAddress.valid, 'fa-times' : !dailyNotificationEmailAddress.valid}"></span>
          <span *ngIf="f.submitted && !dailyNotificationEmailAddress.valid" class="invalid-feedback">
            {{'navOnline.editor.DailyNotificationEmailAddress' | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-right">
          <button *ngIf="!canModifyNavOnline" type="button" (click)="close()" class="btn btn-danger"><i class='fa fa-times'></i>
            {{'navOnline.editor.Close' | translate}}</button>
          <button *ngIf="canModifyNavOnline" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i>
            {{'navOnline.editor.Cancel' | translate}}</button>
          <button *ngIf="canModifyNavOnline" type="submit" class="btn btn-primary" [disabled]="isSaving">
            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i>
            {{isSaving ? ('navOnline.editor.Saving' | translate) : ('navOnline.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>
