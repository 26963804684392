import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

@Injectable()
export class JwtHelper {
    public urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0: { break; }
            case 2: { output += "=="; break; }
            case 3: { output += "="; break; }
            default: {
            throwError("Illegal base64url string!");
            }
        }
        return this.b64DecodeUnicode(output);
    }

    public decodeToken(token: string): any {
        const parts = token.split(".");

        if (parts.length !== 3) {
          throwError("JWT must have 3 parts");
        }

        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
          throwError("Cannot decode the token");
        }

        return JSON.parse(decoded);
    }

    public getTokenExpirationDate(token: string): Date {
        let decoded: any;
        decoded = this.decodeToken(token);

        if (!decoded.hasOwnProperty("exp")) {
            return null;
        }

        const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(decoded.exp);

        return date;
    }

    public isTokenExpired(token: string, offsetSeconds?: number): boolean {
        const date = this.getTokenExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;

        if (date == null) {
            return false;
        }

        // Token expired?
        return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
    }

    // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
    private b64DecodeUnicode(str: any) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), (c: any) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
    }
}
