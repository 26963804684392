<div>
  <div [ngClass]="{'row control-box': router.isActive('/admin', false), 'container': router.isActive('/companies', false)}">
    <header *ngIf="router.isActive('/companies', false)" class="pageHeader">
      <h3><i class="fa fa-building fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.Companies' | translate}}</h3>
    </header>
    <div class="col-lg-8">
      <div class="form-group search-box">
        <search-box (searchChange)="onSearchChanged($event)" placeholder="{{'company.management.Search' | translate}}">></search-box>
      </div>
    </div>
    <div class="col-lg-4 pr-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li *ngIf="canAddNewCompany" class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" (click)="newCompany()">
            <i class="fa fa-plus-circle"></i> {{'company.management.NewCompany' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table table-striped table-hover" [loadingIndicator]="loadingIndicator" [rows]="rows"
    [rowHeight]="35" [columnMode]="'force'" [headerHeight]="35" [footerHeight]="35" [columns]="columns" [scrollbarV]="true"
    [sorts]="[{prop: 'kod', dir: 'asc'}]">
  </ngx-datatable>

  <ng-template #companyNameTemplate let-row="row" let-value="value">
    <strong>{{value}}</strong>
  </ng-template>

  <ng-template #userForSuperAdministratorTemplate let-row="row" let-value="value">
    <span *ngIf="canViewCircles" class="company-circle badge">
      {{value}}
    </span>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <a *ngIf="canModifyCompany" class="btn btn-link btn-sm" href="javascript:;" (click)="editCompany(row)"><i class="fa fa-pencil-square-o"
        aria-hidden="true"></i> {{'company.management.Edit' | translate}}</a>
    {{canModifyNavOnline ? '|' : ''}}
    <a *ngIf="canViewNavOnline" class="btn btn-link btn-sm" href="javascript:;" (click)="editNavOnline(row)"><i class="fa fa-file-text-o"
        aria-hidden="true"></i> {{'company.management.NavOnline' | translate}}</a>
    {{canModifyCompany && canDeleteCompany ? '|' : ''}}
    <a *ngIf="canDeleteCompany" class="btn btn-link btn-sm" href="javascript:;" (click)="deleteCompany(row)"><i class="fa fa-trash-o"
        aria-hidden="true"></i> {{'company.management.Delete' | translate}}</a>
  </ng-template>

  <div class="modal fade" bsModal #companyEditorModal="bs-modal" (onHidden)="onCompanyEditorModalHidden()" [config]="{backdrop: 'static'}"
    tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title float-left"><i class="fa fa-building-o"></i>
            {{editingCompanyName ? ('company.management.EditCompany' | translate:editingCompanyName) : ('company.management.NewCompany' | translate)}}
          </h4>
          <button type="button" class="close float-right" title="Close" (click)="companyEditorModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <company-info #companyEditor></company-info>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" bsModal #navOnlineEditorModal="bs-modal" (onHidden)="onNavOnlineEditorModalHidden()" [config]="{backdrop: 'static'}"
    tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title float-left"><i class="fa fa-building-o"></i> {{('navOnline.management.CreateOrUpdate' | translate)}}</h4>
          <button type="button" class="close float-right" title="Close" (click)="navOnlineEditorModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <nav-online-info #navOnlineEditor></nav-online-info>
        </div>
      </div>
    </div>
  </div>
</div>
