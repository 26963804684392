import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Utilities } from "../../services/utilities";
import { User } from "../../models/user.model";
import { Group } from "../../models/group.model";
import { Permission } from "../../models/permission.model";
import { UserEdit } from "../../models/user-edit.model";
import { UserInfoComponent } from "./user-info.component";

@Component({
  selector: "users-management",
  templateUrl: "./users-management.component.html",
  styleUrls: ["./users-management.component.scss"]
})
export class UsersManagementComponent implements OnInit, AfterViewInit {
  public columns: any[] = [];
  public rows: User[] = [];
  public rowsCache: User[] = [];
  public editedUser: UserEdit;
  public sourceUser: UserEdit;
  public editingUserName: { name: string };
  public loadingIndicator: boolean;

  public allGroups: Group[] = [];

  @ViewChild("indexTemplate", { static: true })
  public indexTemplate: TemplateRef<any>;

  @ViewChild("userNameTemplate", { static: true })
  public userNameTemplate: TemplateRef<any>;

  @ViewChild("userForSuperAdministratorTemplate", { static: true })
  public userForSuperAdministratorTemplate: TemplateRef<any>;

  @ViewChild("groupsTemplate", { static: true })
  public groupsTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  public actionsTemplate: TemplateRef<any>;

  @ViewChild("editorModal", { static: true })
  public editorModal: ModalDirective;

  @ViewChild("userEditor", { static: true })
  public userEditor: UserInfoComponent;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private authService: AuthService) {
  }

  public ngOnInit() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "userName", name: gT("users.management.UserName"), width: 90, cellTemplate: this.userNameTemplate },
      { prop: "fullName", name: gT("users.management.FullName"), width: 120 },
      { prop: "email", name: gT("users.management.Email"), width: 140 },
      { prop: "groups", name: gT("users.management.Groups"), width: 120, cellTemplate: this.groupsTemplate },
    ];

    if (this.canViewCircles) {
      this.columns.push({ prop: "circleName", name: gT("users.management.CircleName"), width: 120, cellTemplate: this.userForSuperAdministratorTemplate });
    }

    if (this.canManageUsers) {
      this.columns.push({ name: "", width: 190, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }

    this.loadData();
  }

  public ngAfterViewInit() {
    this.userEditor.changesSavedCallback = (user) => {

      if (user) {
        Object.assign(this.editedUser, user);
      }

      this.addNewUserToList();
      this.editorModal.hide();
    };

    this.userEditor.changesCancelledCallback = () => {
      if (this.sourceUser) {
        this.sourceUser.isLockedOut = this.editedUser.isLockedOut;
      }
      this.editedUser = null;
      this.editorModal.hide();
    };
  }

  public addNewUserToList() {
    if (this.sourceUser) {
      Object.assign(this.sourceUser, this.editedUser);

      let sourceIndex = this.rowsCache.indexOf(this.sourceUser, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceUser, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedUser = null;
      this.sourceUser = null;
    } else {
      const user = new User();
      Object.assign(user, this.editedUser);
      this.editedUser = null;

      let maxIndex = 0;
      for (const u of this.rowsCache) {
        if ((u as any).index > maxIndex) {
          maxIndex = (u as any).index;
        }
      }

      (user as any).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, user);
      this.rows.splice(0, 0, user);
      this.rows = [...this.rows];
    }
  }

  public loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    if (this.canViewGroups) {
      this.accountService.getUsersAndGroups().subscribe((results) => this.onDataLoadSuccessful(results[0], results[1]), (error) => this.onDataLoadFailed(error));
    } else {
      this.accountService.getUsers().subscribe((users) => this.onDataLoadSuccessful(users, []), (error) => this.onDataLoadFailed(error));
    }
  }

  public onDataLoadSuccessful(users: User[], groups: Group[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    users.forEach((user, index, users) => {
      (user as any).index = index + 1;
    });

    this.rowsCache = [...users];
    this.rows = users;

    this.allGroups = groups;
  }

  public onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage(this.translation("serviceMessage.LoadError"), `${this.translation("serviceMessage.UserLoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);
  }

  public onSearchChanged(value: string) {
    if (this.canViewCircles) {
      this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.userName, r.fullName, r.email, r.phoneNumber, r.jobTitle, r.groups, r.circleName));
    } else {
      this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.userName, r.fullName, r.email, r.phoneNumber, r.jobTitle, r.groups));
    }
  }

  public onEditorModalHidden() {
    this.editingUserName = null;
    this.userEditor.resetForm(true);
  }

  public newUser() {
    this.editingUserName = null;
    this.sourceUser = null;
    this.editedUser = this.userEditor.newUser(this.allGroups);
    this.editorModal.show();
  }

  public editUser(row: UserEdit) {
    this.editingUserName = { name: row.userName };
    this.sourceUser = row;
    this.editedUser = this.userEditor.editUser(row, this.allGroups);
    this.editorModal.show();
  }

  public deleteUser(row: UserEdit) {
    this.alertService.showDialog(`${this.translation("serviceMessage.ConfirmDelete")} ${row.userName}`, DialogType.confirm, () => this.deleteUserHelper(row));
  }

  public deleteUserHelper(row: UserEdit) {
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Deleting"));
    this.loadingIndicator = true;

    this.accountService.deleteUser(row)
      .subscribe((results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter((item) => item !== row);
        this.rows = this.rows.filter((item) => item !== row);
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.DeleteError")}"${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  get canViewGroups() {
    return this.accountService.userHasPermission(Permission.viewGroupsPermission);
  }

  get canViewCircles() {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }

  get canManageUsers() {
    return this.accountService.userHasPermission(Permission.manageUsersPermission);
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }
}
