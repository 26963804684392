import { Component } from "@angular/core";
import { fadeInOut } from "../../services/animations";

@Component({
    animations: [fadeInOut],
    selector: "about",
    styleUrls: ["./about.component.scss"],
    templateUrl: "./about.component.html",
})
export class AboutComponent {
}