import { Component, ViewChild } from "@angular/core";

import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { Group } from "../../models/group.model";
import { Role } from "../../models/role.model";
import { Permission } from "../../models/permission.model";

import { Utilities } from "./../../services/utilities";

@Component({
  selector: "group-editor",
  templateUrl: "./group-editor.component.html",
  styleUrls: ["./group-editor.component.scss"]
})
export class GroupEditorComponent {
  private isNewGroup = false;
  public isSaving: boolean;
  public showValidationErrors: boolean = true;
  private editingGroupName: string;
  public groupEdit: Group = new Group();
  public allRoles: Role[] = [];
  public selectedRoles: Role[] = [];

  public dualListBoxKeyName = "id";
  public dualListBoxDisplayName = "description";
  public formResetToggle = true;

  public changesSavedCallback: (group: Group) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  public format = Utilities.getDualListboxFilter();

  @ViewChild("f", { static: false })
  private form;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService) {
  }

  public resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  public newGroup(allRoles: Role[]) {
    this.isNewGroup = true;
    this.showValidationErrors = true;

    this.editingGroupName = null;
    this.allRoles = allRoles;
    this.selectedRoles = [];
    this.groupEdit = new Group();

    return this.groupEdit;
  }

  public editGroup(group: Group, allRoles: Role[]) {
    if (group) {
      this.isNewGroup = false;
      this.showValidationErrors = true;

      this.editingGroupName = group.name;
      this.allRoles = allRoles;
      this.selectedRoles = [];
      group.roles.forEach((role) => this.selectedRoles.push(role));
      this.groupEdit = new Group();
      Object.assign(this.groupEdit, group);

      return this.groupEdit;
    } else {
      return this.newGroup(allRoles);
    }
  }

  get canManageGroups() {
    return this.accountService.userHasPermission(Permission.manageGroupsPermission);
  }

  public showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  public save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));

    this.groupEdit.roles = this.selectedRoles;

    if (this.isNewGroup) {
      this.accountService.newGroup(this.groupEdit).subscribe((group) => this.saveSuccessHelper(group), (error) => this.saveFailedHelper(error));
    } else {
      this.accountService.updateGroup(this.groupEdit).subscribe((response) => this.saveSuccessHelper(), (error) => this.saveFailedHelper(error));
    }
  }

  private saveSuccessHelper(group?: Group) {
    if (group) {
      Object.assign(this.groupEdit, group);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewGroup) {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.groupEdit.name} ${this.translation("serviceMessage.CreateSuccess")}`, MessageSeverity.success);
    } else {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.groupEdit.name} ${this.translation("serviceMessage.UpdateSuccess")}`, MessageSeverity.success);
    }

    this.groupEdit = new Group();
    this.resetForm();

    if (this.changesSavedCallback) {
      this.changesSavedCallback(group);
    }
  }

  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser()
      .subscribe((user) => { },
        (error) => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UserLoadError"), MessageSeverity.error, error);
        });
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    const errorMessage = Utilities.getHttpResponseMessage(error);
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, errorMessage);
    this.alertService.showStickyMessage(errorMessage, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  public cancel() {
    this.groupEdit = new Group();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(this.translation("serviceMessage.Cancelled"), this.translation("serviceMessage.TaskCancelled"), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }
}
