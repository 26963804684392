export class UserSignUp {
    public userName: string;
    public newPassword: string;
    public confirmPassword: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public jobTitle: string;
    public phoneNumber: string;
}
