import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Utilities } from "../../services/utilities";
import { Circle } from "../../models/circle.model";
import { User } from "../../models/user.model";
import { Company } from "../../models/company.model";
import { Permission } from "../../models/permission.model";
import { CircleEditorComponent } from "./circle-editor.component";

@Component({
  selector: "circle-management",
  templateUrl: "./circle-management.component.html",
  styleUrls: ["./circle-management.component.scss"]
})
export class CircleManagementComponent implements OnInit, AfterViewInit {
  public columns: any[] = [];
  public rows: Circle[] = [];
  public rowsCache: Circle[] = [];
  public allUsers: User[] = [];
  public allCompanies: Company[] = [];
  public editedCircle: Circle;
  public sourceCircle: Circle;
  public editingCircleName: { name: string };
  public loadingIndicator: boolean;

  //@ViewChild("indexTemplate", { static: true })
  //public indexTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  public actionsTemplate: TemplateRef<any>;

  @ViewChild("editorModal", { static: true })
  public editorModal: ModalDirective;

  @ViewChild("circleEditor", { static: true })
  public circleEditor: CircleEditorComponent;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private authService: AuthService) {
  }

  public ngOnInit() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "name", name: gT("circles.management.Name"), width: 180 },
      { prop: "description", name: gT("circles.management.Description"), width: 260 },
      { name: "", width: 190, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
    ];

    this.loadData();
  }

  public ngAfterViewInit() {
    this.circleEditor.changesSavedCallback = (circle) => {

      if (circle) {
        Object.assign(this.editedCircle, circle);
      }

      this.addNewCircleToList();
      this.editorModal.hide();
    };

    this.circleEditor.changesCancelledCallback = () => {
      this.editedCircle = null;
      this.sourceCircle = null;
      this.editorModal.hide();
    };
  }

  public addNewCircleToList() {
    if (this.sourceCircle) {
      Object.assign(this.sourceCircle, this.editedCircle);

      let sourceIndex = this.rowsCache.indexOf(this.sourceCircle, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceCircle, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedCircle = null;
      this.sourceCircle = null;
    } else {
      const circle = new Circle();
      Object.assign(circle, this.editedCircle);
      this.editedCircle = null;

      let maxIndex = 0;
      for (const r of this.rowsCache) {
        if ((r as any).index > maxIndex) {
          maxIndex = (r as any).index;
        }
      }

      (circle as any).index = maxIndex + 1;

      this.rowsCache.push(circle);
      this.rows.push(circle);
      this.rows = [...this.rows];
    }
  }

  public loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.accountService.getCircles()
      .subscribe((circles) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        circles.forEach((circle, index, internalCircles) => {
          (circle as any).index = index + 1;
        });

        this.rowsCache = [...circles];
        this.rows = circles;
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  public onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter((r) => Utilities.searchArray(value, false, r.name, r.description));
  }

  public onEditorModalHidden() {
    this.editingCircleName = null;
    this.circleEditor.resetForm(true);
  }

  public newCircle() {
    this.editingCircleName = null;
    this.sourceCircle = null;
    this.editedCircle = this.circleEditor.newCircle([], []);
    this.editorModal.show();
  }

  public editCircle(row: Circle) {
    this.editingCircleName = { name: row.name };
    this.sourceCircle = row;
    this.accountService.getCompaniesAndUsers(row.id).subscribe((results) => {
      this.editedCircle = this.circleEditor.editCircle(row, results[0], results[1]);
      this.editorModal.show();
    }, (error) => {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;

      this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.CompanyLoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.error, error);
    });
  }

  public deleteCircle(row: Circle) {
    this.alertService.showDialog(`${this.translation("serviceMessage.ConfirmDelete")} ${row.name}`, DialogType.confirm, () => this.deleteCircleHelper(row));
  }

  public deleteCircleHelper(row: Circle) {
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Deleting"));
    this.loadingIndicator = true;

    this.accountService.deleteCircle(row.id)
      .subscribe((successful) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        if (successful) {
          this.rowsCache = this.rowsCache.filter((item) => item !== row);
          this.rows = this.rows.filter((item) => item !== row);
        }
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.DeleteError")}"${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }

  get canManageCircles() {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }
}
