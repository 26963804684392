import { Component } from "@angular/core";
import { DualListComponent } from "angular-dual-listbox";

@Component({
    selector: "custom-dual-list",
    styleUrls: ["./custom-dual-list.component.scss"],
    templateUrl: "./custom-dual-list.component.html",
})

export class CustomDualListComponent extends DualListComponent {
}