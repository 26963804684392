import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { mergeMap, switchMap, catchError  } from "rxjs/operators";
// import { OAuthService } from "angular-oauth2-oidc";

import { LocalStoreManager } from "./local-store-manager.service";
import { ConfigurationService } from "./configuration.service";
import { DbKeys } from "./db-keys";
import { LoginResponse } from "../models/login-response.model";
import { UserSignUp } from "../models/user-sign-up.model";

@Injectable()
export class OidcHelperService {

  private readonly _loginUrl: string = "/connect/token";
  private readonly _signUpUrl: string = "/api/account/signUp";

  private get loginUrl() { return this.baseUrl + this._loginUrl; }
  private get signUpUrl() { return this.baseUrl + this._signUpUrl; }

  private get baseUrl() { return this.configurations.baseUrl; }
  private clientId = "quickapp_spa";
  private scope = "openid email phone profile offline_access roles quickapp_api";

  constructor(
    private http: HttpClient,
    // private oauthService: OAuthService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager) {

  }

  public getLoginEndpoint<T>(userName: string, password: string): Observable<T> {
    const header = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });

    const params = new HttpParams()
      .append("username", userName)
      .append("password", password)
      .append("grant_type", "password")
      .append("scope", "openid email phone profile offline_access roles")
      .append("resource", window.location.origin);

    const requestBody = params.toString();


    return this.http.post<T>(this.loginUrl, requestBody, { headers: header }).pipe();
  }

  public getSignUpEndpoint<T>(user: UserSignUp): Observable<T> {
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post<T>(this.signUpUrl, JSON.stringify(user), { headers: header });
  }


  public getRefreshLoginEndpoint<T>(): Observable<T> {
    const header = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });

    const params = new HttpParams()
      .append("refresh_token", this.refreshToken)
      .append("grant_type", "refresh_token")
      .append("scope", "openid email phone profile offline_access roles");

    const requestBody = params.toString();

    return this.http.post<T>(this.loginUrl, requestBody, { headers: header }).pipe();
  }

  get accessToken(): string {
    return this.localStorage.getData(DbKeys.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date {
    return this.localStorage.getDataObject<Date>(DbKeys.TOKEN_EXPIRES_IN, true);
  }

  get refreshToken(): string {
    return this.localStorage.getData(DbKeys.REFRESH_TOKEN);
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
  }
}
