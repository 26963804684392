import { Component, ViewChild } from "@angular/core";
import { orderBy, sortBy } from "lodash";

import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Circle } from "../../models/circle.model";
import { Company } from "../../models/company.model";
import { User } from "../../models/user.model";

import { Utilities } from "./../../services/utilities";

@Component({
  selector: "circle-editor",
  styleUrls: ["./circle-editor.component.scss"],
  templateUrl: "./circle-editor.component.html",
})
export class CircleEditorComponent {
  private isNewCircle = false;
  public isSaving: boolean;
  public showValidationErrors: boolean = true;
  private editingCircleName: string;
  public circleEdit: Circle = new Circle();
  private allCompaniesCache: Company[] = [];
  public allCompanies: string[] = [];
  public selectedCompanies: string[] = [];
  public allUsers: User[] = [];
  public selectedUsers: User[] = [];

  public uniqueId: string = Utilities.uniqueId();

  public dualListBoxUsersKeyName = "id";
  public dualListBoxUsersDisplayName = "fullName";

  public dualListBoxCompaniesKeyName = "nev";
  public dualListBoxCompaniesDisplayName = "nev";

  public formResetToggle = true;

  public changesSavedCallback: (circle: Circle) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  public format = Utilities.getDualListboxFilter();

  @ViewChild("f", { static: false })
  private form;

  @ViewChild("circleName", { static: false })
  private circleName;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private authService: AuthService) {
  }

  public resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  public newCircle(allCompanies: Company[], allUsers: User[]) {
    this.isNewCircle = true;
    this.showValidationErrors = true;

    this.editingCircleName = null;
    this.allCompanies = allCompanies.map((company) => company.nev).sort();
    this.selectedCompanies = [];
    this.allUsers = allUsers;
    this.selectedUsers = [];
    this.circleEdit = new Circle();

    return this.circleEdit;
  }

  public editCircle(circle: Circle, allCompanies: Company[], allUsers: User[]) {
    if (circle) {
      this.isNewCircle = false;
      this.showValidationErrors = true;

      this.editingCircleName = circle.name;
      this.allCompaniesCache = allCompanies;
      this.allCompanies = allCompanies.map((company) => company.nev).sort();

      this.selectedCompanies = [];
      if (circle.companyIds) {
        circle.companyIds.forEach((companyId) => {
          const company = allCompanies.filter((company) => company.id === companyId)[0];
          this.selectedCompanies.push(company.nev);
        });
      }

      this.selectedCompanies = this.selectedCompanies.sort();
      const allUsersTmp = allUsers.filter((user) => !user.circleName);
      this.allUsers = orderBy(allUsersTmp, [(o) => o.fullName]);

      this.selectedUsers = [];
      this.allUsers = [];
      circle.userIds.forEach((userId) => {
        const user = allUsers.find((user) => user.id === userId);
        this.selectedUsers.push(user);
        if (!this.allUsers.some((u) => u.id === user.id)) {
          this.allUsers.push(user);
        }
      });

      if (allUsers) {
        allUsers.forEach((user) => {
          if (user.circleName === "") {
            this.allUsers.push(user);
          }
        });
      }

      this.selectedUsers = orderBy(this.selectedUsers, [(o) => o.fullName]);
      this.circleEdit = new Circle();
      Object.assign(this.circleEdit, circle);

      return this.circleEdit;
    } else {
      return this.newCircle(allCompanies, allUsers);
    }
  }

  get canManageCircles() {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  public save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));

    this.circleEdit.companyIds = [];
    this.selectedCompanies.forEach((companyNev) => {
      const company = this.allCompaniesCache.filter((company) => company.nev === companyNev)[0];
      this.circleEdit.companyIds.push(company.id);
    });
    this.circleEdit.userIds = this.selectedUsers.map((user) => user.id);

    if (this.isNewCircle) {
      this.accountService.newCircle(this.circleEdit).subscribe((circle) => this.saveSuccessHelper(circle), (error) => this.saveFailedHelper(error));
    } else {
      this.accountService.updateCircle(this.circleEdit).subscribe((successful) => this.saveSuccessHelper(), (error) => this.saveFailedHelper(error));
    }
  }

  private saveSuccessHelper(circle?: Circle) {

    if (circle) {
      Object.assign(this.circleEdit, circle);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewCircle) {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.circleEdit.name} ${this.translation("serviceMessage.CreateSuccess")}`, MessageSeverity.success);
    } else {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.circleEdit.name} ${this.translation("serviceMessage.UpdateSuccess")}`, MessageSeverity.success);
    }

    this.circleEdit = new Circle();
    this.resetForm();

    if (this.changesSavedCallback) {
      this.changesSavedCallback(circle);
    }
  }

  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser()
      .subscribe((user) => { },
        (error) => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UserLoadError"), MessageSeverity.error, error);
        });
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    const errorMessage = Utilities.getHttpResponseMessage(error);
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, errorMessage);
    this.alertService.showStickyMessage(errorMessage, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  public cancel() {
    this.circleEdit = new Circle();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(this.translation("serviceMessage.Cancelled"), this.translation("serviceMessage.TaskCancelled"), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }
}

export class CompanyMiniDto {

  constructor(id: number, nev: string) {
    this.id = id;
    this.nev = nev;
  }

  public id: number;
  public nev: string;
}
