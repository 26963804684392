import { NgModule, Injectable } from "@angular/core";
import { Routes, RouterModule, DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

import { LoginComponent } from "./components/login/login.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { HomeComponent } from "./components/home/home.component";
import { CompanyManagementComponent } from "./components/company/company.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { AdminComponent } from "./components/admin/admin.component";
import { AboutComponent } from "./components/about/about.component";
import { ContactComponent } from "./components/contact/contact.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AccountConfirmEmailComponent } from "./components/account/account-confirm-email.component";
import { AccountConfirmRegistratrionComponent } from "./components/account/account-confirm-registratrion.component";
import { FeaturesComponent } from "./components/features/features.component";
import { AccountingFeaturesComponent } from "./components/features/accounting/accounting-features.component";
import { AccountingManualComponent } from "./components/features/accounting/accounting-manual.component";
import { EinvoiceFeaturesComponent } from "./components/features/einvoice/einvoice-features.component";
import { LegalComponent } from "./components/legal/legal.component";

import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth-guard.service";
import { Utilities } from "./services/utilities";

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}

const routes: Routes = [
  { path: "", redirectTo: "about", pathMatch: "full" },
  { path: "legal", component: LegalComponent, data: { title: "ÁSZF és adatvédelem" } },
  { path: "login", component: LoginComponent, data: { title: "Bejelentkezés" } },
  { path: "signup", component: SignUpComponent, data: { title: "Regisztráció" } },
  { path: "settings", component: SettingsComponent, canActivate: [AuthGuard], data: { title: "Beállítások" } },
  { path: "admin", component: AdminComponent, canActivate: [AuthGuard], data: { title: "Admin" } },
  { path: "companies", component: CompanyManagementComponent, canActivate: [AuthGuard], data: { title: "Cégek" } },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard], data: { title: "Kezdőképernyő" } },
  { path: "about", component: AboutComponent, data: { title: "Névjegy" } },
  { path: "contact", component: ContactComponent, data: { title: "Kapcsolat" } },
  { path: "features", component: FeaturesComponent, data: { title: "Keretrendszer" } },
  { path: "features/accounting-features", component: AccountingFeaturesComponent, data: { title: "Kettős könyvvitel" } },
  { path: "features/accounting-manual", component: AccountingManualComponent, data: { title: "Kettős könyvvitel leírás" } },
  { path: "features/einvoice-features", component: EinvoiceFeaturesComponent, data: { title: "NAV online számla" } },
  { path: "account-confirm/email/:userId/:code", component: AccountConfirmEmailComponent },
  { path: "account-confirm/registration/:userId", component: AccountConfirmRegistratrionComponent },
  { path: "**", component: NotFoundComponent, data: { title: "Az oldal nem található" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }]
})
export class AppRoutingModule { }
