<div>
  <form *ngIf="formResetToggle" class="form-horizontal" name="companyInfoForm" #f="ngForm" novalidate (ngSubmit)="f.form.valid ? save():

          (!kod.valid && showErrorAlert('Kód megadása kötelező!', ''));
          (!nev.valid && showErrorAlert('Cég név megadása kötelező!', ''));
          (!rovidNev.valid && showErrorAlert('Cég rövid név megadása kötelező!', ''));

          ">

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="kod-{{uniqueId}}">{{'company.editor.Kod' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && kod.valid, 'is-invalid' : f.submitted && !kod.valid}">
        <input autofocus type="text" attr.id="kod-{{uniqueId}}" name="kod" placeholder="A kód kötelező adat és legfejlebb 5 karakterből áll."
          class="form-control" [(ngModel)]="company.kod" #kod="ngModel" required minlength="1" maxlength="5" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': kod.valid, 'fa-times' : !kod.valid}"></span>
        <span *ngIf="f.submitted && !kod.valid" class="invalid-feedback">
          {{'company.editor.KodRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row has-feedback-icon">
      <label class="col-form-label col-lg-3" for="nev-{{uniqueId}}">{{'company.editor.Nev' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && nev.valid, 'is-invalid' : f.submitted && !nev.valid}">
        <input type="text" attr.id="nev-{{uniqueId}}" name="nev" placeholder="A cég neve kötelező adat és legfeljebb 40 karakterből áll."
          class="form-control" [(ngModel)]="company.nev" #nev="ngModel" required minlength="2" maxlength="40" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': nev.valid, 'fa-times' : !nev.valid}"></span>
        <span *ngIf="f.submitted && !nev.valid" class="invalid-feedback">
          {{'company.editor.NevRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="fullName-{{uniqueId}}">{{'company.editor.RovidNev' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && rovidNev.valid, 'is-invalid' : f.submitted && !rovidNev.valid}">
        <input type="text" attr.id="rovidNev-{{uniqueId}}" name="rovidNev" placeholder="A rövid név kötelező adat és legfeljebb 20 karakterből áll."
          class="form-control" [(ngModel)]="company.rovidNev" #rovidNev="ngModel" required minlength="2" maxlength="20" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': rovidNev.valid, 'fa-times' : !rovidNev.valid}"></span>
        <span *ngIf="f.submitted && !rovidNev.valid" class="invalid-feedback">
          {{'company.editor.RovidNevRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="adoSzamHU-{{uniqueId}}">{{'company.editor.AdoSzamHU' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && adoSzamHU.valid, 'is-invalid' : f.submitted && !adoSzamHU.valid}">
        <input type="text" attr.id="adoSzamHU-{{uniqueId}}" name="adoSzamHU"
          placeholder="A magyar adószámot ajda meg kötőjelek nélkül! A szám 11 számjegyből áll." class="form-control" [(ngModel)]="company.adoSzamHU"
          #adoSzamHU="ngModel" maxlength="11" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': adoSzamHU.valid, 'fa-times' : !adoSzamHU.valid}"></span>
        <span *ngIf="f.submitted && !adoSzamHU.valid" class="invalid-feedback">
          {{'company.editor.AdoSzamHUValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="adoSzamEU-{{uniqueId}}">{{'company.editor.AdoSzamEU' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && adoSzamEU.valid, 'is-invalid' : f.submitted && !adoSzamEU.valid}">
        <input type="text" attr.id="adoSzamEU-{{uniqueId}}" name="adoSzamEU"
          placeholder="Ide írhatja be a cég úniós adószámát, ha rendelkezik ilyennel. A szám legfeljebb 20 karakterből áll." class="form-control"
          [(ngModel)]="company.adoSzamEU" #adoSzamEU="ngModel" maxlength="20" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': adoSzamEU.valid, 'fa-times' : !adoSzamEU.valid}"></span>
        <span *ngIf="f.submitted && !adoSzamEU.valid" class="invalid-feedback">
          {{'company.editor.AdoSzamEUValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="adoAzon-{{uniqueId}}">{{'company.editor.AdoAzon' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && adoAzon.valid, 'is-invalid' : f.submitted && !adoAzon.valid}">
        <input type="text" attr.id="adoAzon-{{uniqueId}}" name="adoAzon"
          placeholder="Amennyiben van adóazonosító jel, azt kell itt megadnia. A jel 10 számjegyből áll." class="form-control"
          [(ngModel)]="company.adoAzon" #adoAzon="ngModel" maxlength="10" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': adoAzon.valid, 'fa-times' : !adoAzon.valid}"></span>
        <span *ngIf="f.submitted && !adoAzon.valid" class="invalid-feedback">
          {{'company.editor.AdoAzonValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="orszagKod-{{uniqueId}}">{{'company.editor.OrszagKod' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && orszagKod.valid, 'is-invalid' : f.submitted && !orszagKod.valid}">
        <input type="text" attr.id="orszagKod-{{uniqueId}}" name="orszagKod"
          placeholder="Az ország kód kötelező adat és két nagybetűből áll. Magyarország esetén írjon HU-t!" class="form-control"
          [(ngModel)]="company.orszagKod" #orszagKod="ngModel" required maxlength="2" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': orszagKod.valid, 'fa-times' : !orszagKod.valid}"></span>
        <span *ngIf="f.submitted && !orszagKod.valid" class="invalid-feedback">
          {{'company.editor.OrszagKodRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="iranyitoSzam-{{uniqueId}}">{{'company.editor.IranyitoSzam' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && iranyitoSzam.valid, 'is-invalid' : f.submitted && !iranyitoSzam.valid}">
        <input type="text" attr.id="iranyitoSzam-{{uniqueId}}" name="iranyitoSzam"
          placeholder="A postai irányítószám legfeljebb 10 karakter hosszú lehet." class="form-control" [(ngModel)]="company.iranyitoSzam"
          #iranyitoSzam="ngModel" maxlength="10" />
        <span *ngIf="f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': iranyitoSzam.valid, 'fa-times' : !iranyitoSzam.valid}"></span>
        <span *ngIf="f.submitted && !iranyitoSzam.valid" class="invalid-feedback">
          {{'company.editor.IranyitoSzamValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="telepules-{{uniqueId}}">{{'company.editor.Telepules' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && telepules.valid, 'is-invalid' : f.submitted && !telepules.valid}">
        <input type="text" attr.id="telepules-{{uniqueId}}" name="telepules" placeholder="A település kötelező adat és legfeljebb 30 betűből áll."
          class="form-control" [(ngModel)]="company.telepules" #telepules="ngModel" required maxlength="30" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': telepules.valid, 'fa-times' : !telepules.valid}"></span>
        <span *ngIf="f.submitted && !telepules.valid" class="invalid-feedback">
          {{'company.editor.TelepulesRequired' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="kozteruletNeve-{{uniqueId}}">{{'company.editor.KozteruletNeve' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && kozteruletNeve.valid, 'is-invalid' : f.submitted && !kozteruletNeve.valid}">
        <input type="text" attr.id="kozteruletNeve-{{uniqueId}}" name="kozteruletNeve" placeholder="A közterület neve legfeljebb 40 karakterből áll."
          class="form-control" [(ngModel)]="company.kozteruletNeve" #kozteruletNeve="ngModel" maxlength="40" />
        <span *ngIf="f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': kozteruletNeve.valid, 'fa-times' : !kozteruletNeve.valid}"></span>
        <span *ngIf="f.submitted && !kozteruletNeve.valid" class="invalid-feedback">
          {{'company.editor.KozteruletNeveValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="kozteruletJellege-{{uniqueId}}">{{'company.editor.KozteruletJellege' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && kozteruletJellege.valid, 'is-invalid' : f.submitted && !kozteruletJellege.valid}">
        <input type="text" attr.id="kozteruletJellege-{{uniqueId}}" name="kozteruletJellege"
          placeholder="Ide a közterület jellegét írja be: utca, tér, stb. Legfeljebb 6 betű adható meg." class="form-control"
          [(ngModel)]="company.kozteruletJellege" #kozteruletJellege="ngModel" maxlength="6" />
        <span *ngIf="f.submitted" class="fa form-control-feedback"
          [ngClass]="{'fa-check': kozteruletJellege.valid, 'fa-times' : !kozteruletJellege.valid}"></span>
        <span *ngIf="f.submitted && !kozteruletJellege.valid" class="invalid-feedback">
          {{'company.editor.KozteruletJellege' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="hazszam-{{uniqueId}}">{{'company.editor.Hazszam' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && hazszam.valid, 'is-invalid' : f.submitted && !hazszam.valid}">
        <input type="text" attr.id="hazszam-{{uniqueId}}" name="hazszam" placeholder="A házszám adat legfeljebb 10 karakterből áll."
          class="form-control" [(ngModel)]="company.hazszam" #hazszam="ngModel" maxlength="10" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': hazszam.valid, 'fa-times' : !hazszam.valid}"></span>
        <span *ngIf="f.submitted && !hazszam.valid" class="invalid-feedback">
          {{'company.editor.HazszamValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-lg-3" for="kodAktEv-{{uniqueId}}">{{'company.editor.AktualisEv' | translate}}</label>
      <div class="col-lg-9" [ngClass]="{'is-valid': f.submitted && kodAktEv.valid, 'is-invalid' : f.submitted && !kodAktEv.valid}">
        <input type="text" attr.id="kodAktEv-{{uniqueId}}" name="kodAktEv"
          placeholder="Az aktuális év mezőbe az alapértelmezett üzleti év kódját írja be! Az adat legfeljebb 3 karakterből áll." class="form-control"
          [(ngModel)]="company.kodAktEv" #kodAktEv="ngModel" maxlength="3" />
        <span *ngIf="f.submitted" class="fa form-control-feedback" [ngClass]="{'fa-check': kodAktEv.valid, 'fa-times' : !kodAktEv.valid}"></span>
        <span *ngIf="f.submitted && !kodAktEv.valid" class="invalid-feedback">
          {{'company.editor.AktualisEvValidation' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <hr class="edit-separator-hr" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-right">
          <button type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i>
            {{'company.editor.Cancel' | translate}}</button>
          <button type="submit" class="btn btn-primary" [disabled]="isSaving">
            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i>
            {{isSaving ? ('company.editor.Saving' | translate) : ('company.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </form>
</div>
