import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import { orderBy, sortBy } from "lodash";

import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { MasterDataService } from "../../services/master-data.service";
import { Utilities } from "../../services/utilities";
import { Company } from "../../models/company.model";
import { User } from "../../models/user.model";
import { UserEdit } from "../../models/user-edit.model";
import { Group } from "../../models/group.model";
import { Permission } from "../../models/permission.model";


@Component({
  selector: "user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"]
})
export class UserInfoComponent implements OnInit {
  public isEditMode = false;
  public isNewUser = false;
  public isSaving = false;
  public isChangePassword = false;
  public isEditingSelf = false;
  public showValidationErrors = false;
  private editingUserName: string;
  public uniqueId: string = Utilities.uniqueId();
  public user: User = new User();
  public userEdit: UserEdit;
  public allGroups: Group[] = [];
  public allCompanies: Company[] = [];
  public selectedCompanies: Company[] = [];

  public dualListBoxKeyName = "id";
  public dualListBoxDisplayName = "nev";
  public formResetToggle = true;

  public changesSavedCallback: (user: User) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  public format = Utilities.getDualListboxFilter();

  @Input()
  public isViewOnly: boolean;

  @Input()
  public isGeneralEditor = false;

  @ViewChild("f", { static: false })
  private form;

  // ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild("userName", { static: false })
  public userName;

  @ViewChild("userPassword", { static: false })
  public userPassword;

  @ViewChild("email", { static: false })
  public email;

  @ViewChild("currentPassword", { static: false })
  public currentPassword;

  @ViewChild("newPassword", { static: false })
  public newPassword;

  @ViewChild("confirmPassword", { static: false })
  public confirmPassword;

  @ViewChild("groups", { static: false })
  public groups;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private masterDataService: MasterDataService,
    public router: Router) {
  }

  public ngOnInit() {
    if (!this.isGeneralEditor) {
      this.loadCurrentUserData();
    }
  }

  public resetForm(replace = false) {
    this.isChangePassword = false;

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  public newUser(allGroups: Group[]) {
    this.isGeneralEditor = true;
    this.isNewUser = true;

    this.allGroups = allGroups;
    this.editingUserName = null;
    this.user = this.userEdit = new UserEdit();
    this.edit();

    return this.userEdit;
  }

  public editUser(user: User, allGroups: Group[]) {
    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;

      this.setGroups(user, allGroups);
      this.editingUserName = user.userName;
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);
      this.edit();

      return this.userEdit;
    } else {
      return this.newUser(allGroups);
    }
  }

  public displayUser(user: User, allGroups?: Group[]) {
    this.user = new User();
    Object.assign(this.user, user);
    this.deletePasswordFromUser(this.user);
    this.setGroups(user, allGroups);

    this.isEditMode = false;
  }

  public deletePasswordFromUser(user: UserEdit | User) {
    const userEdit = user as UserEdit;

    delete userEdit.currentPassword;
    delete userEdit.newPassword;
    delete userEdit.confirmPassword;
  }

  private loadCurrentUserData(): void {
    this.alertService.startLoadingMessage();

    if (this.canViewAllGroups) {
      this.accountService.getUserAndGroups().subscribe((results) => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), (error) => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe((user) => this.onCurrentUserDataLoadSuccessful(user, []), (error) => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, groups: Group[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allGroups = groups;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.UserLoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }

  public getGroupByName(name: string) {
    return this.allGroups.find((r) => r.name === name);
  }

  public showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  public edit() {
    this.isChangePassword = false;

    if (this.isNewUser) {
      this.isChangePassword = true;
      this.masterDataService.getUserCompanies(this.accountService.currentUser.id).subscribe((result) => this.setCompanies(result[0], new Array<number>()), (error) => this.onUserCompanyDataLoadFailed(error));
    } else if (this.canAssignCompanies && !this.isNewUser) {
      this.masterDataService.getUserCompanies(this.user.id).subscribe((result) => this.setCompanies(result[0], result[1]), (error) => this.onUserCompanyDataLoadFailed(error));
    }

    if (!this.isGeneralEditor) {
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      Object.assign(this.userEdit, this.user);
    } else {
      if (!this.userEdit) {
        this.userEdit = new UserEdit();
      }

      this.isEditingSelf = this.accountService.currentUser ? this.userEdit.id === this.accountService.currentUser.id : false;
    }

    this.isEditMode = true;
    this.showValidationErrors = true;
  }

  public save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));

    if (this.isNewUser) {
      this.accountService.newUser(this.userEdit).subscribe((data) => {
        this.user.id = data.userId;
        this.saveSuccessHelper(this.user);
        this.updateUserCompanies(data.userId);
      }, (error) => this.saveFailedHelper(error));
    } else {
      this.accountService.updateUser(this.userEdit).subscribe((response) => this.saveSuccessHelper(), (error) => this.saveFailedHelper(error));
      if (this.accountService.currentUser.isAdministrator) {
        this.updateUserCompanies(this.user.id);
      }
    }
  }

  private updateUserCompanies(userId: number) {
    this.masterDataService.updateUserCompanies(userId, this.selectedCompanies.map((company) => company.id)).subscribe((response) => {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), this.translation("serviceMessage.UserCompaniesUpdated"), MessageSeverity.success);
    }, (error) => this.onUserCompanyDataLoadFailed(error));
  }

  private saveSuccessHelper(user?: User) {
    this.testIsGroupUserCountChanged(this.user, this.userEdit);

    if (user) {
      user.fullName = `${user.lastName} ${user.firstName}`;
      Object.assign(this.userEdit, user);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;

    this.deletePasswordFromUser(this.userEdit);
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();

    if (this.isGeneralEditor) {
      if (this.isNewUser) {
        this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.user.userName} ${this.translation("serviceMessage.CreateSuccess")}`, MessageSeverity.success);
      } else if (!this.isEditingSelf) {
        this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.user.userName} ${this.translation("serviceMessage.UpdateSuccess")}`, MessageSeverity.success);
      }
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), this.translation("serviceMessage.UserProfileSaved"), MessageSeverity.success);
      // this.refreshLoggedInUser();
    }

    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback(user);
    }

    this.resetForm();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    const errorMessage = Utilities.getHttpResponseMessage(error);

    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, errorMessage);
    this.alertService.showStickyMessage(errorMessage, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  private testIsGroupUserCountChanged(currentUser: User, editedUser: User) {
    const groupsAdded = this.isNewUser ? editedUser.groups : editedUser.groups.filter((group) => currentUser.groups.indexOf(group) === -1);
    const groupsRemoved = this.isNewUser ? [] : currentUser.groups.filter((group) => editedUser.groups.indexOf(group) === -1);

    const modifiedGroups = groupsAdded.concat(groupsRemoved);

    if (modifiedGroups.length) {
      setTimeout(() => this.accountService.onGroupsUserCountChanged(modifiedGroups));
    }
  }

  public cancel() {
    if (this.isGeneralEditor) {
      this.userEdit = this.user = new UserEdit();
    } else {
      this.userEdit = new UserEdit();
    }

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(this.translation("serviceMessage.Cancelled"), this.translation("serviceMessage.TaskCancelled"), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  public close() {
    this.userEdit = this.user = new UserEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback(null);
    }
  }

  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser()
      .subscribe((user) => {
        this.loadCurrentUserData();
      },
        (error) => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UserLoadError"), MessageSeverity.error, error);
        });
  }

  public changePassword() {
    this.isChangePassword = true;
  }

  public unlockUser() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));

    this.accountService.unblockUser(this.userEdit.id)
      .subscribe((response) => {
        this.isSaving = false;
        this.userEdit.isLockedOut = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage(this.translation("serviceMessage.Success"), this.translation("serviceMessage.UserUnblocked"), MessageSeverity.success);
      },
        (error) => {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        });
  }

  public lockUser() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));

    this.accountService.blockUser(this.userEdit.id)
      .subscribe((response) => {
        this.isSaving = false;
        this.userEdit.isLockedOut = true;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage(this.translation("serviceMessage.Success"), this.translation("serviceMessage.UserBlocked"), MessageSeverity.success);
      },
        (error) => {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        });
  }

  private setGroups(user: User, allGroups?: Group[]) {
    this.allGroups = allGroups ? [...allGroups] : [];

    if (user.groups) {
      for (const ur of user.groups) {
        if (!this.allGroups.some((r) => r.name === ur)) {
          this.allGroups.unshift(new Group(ur));
        }
      }
    }
  }

  private setCompanies(availableCompanies: Company[], selectedCompanyIds: number[]) {
    this.allCompanies = availableCompanies;
    this.selectedCompanies = [];

    for (const company of this.allCompanies) {
      if (selectedCompanyIds.some((companyId) => company.id === companyId)) {
        this.selectedCompanies.push(company);
      }
    }

    this.selectedCompanies = orderBy(this.selectedCompanies, [(o) => o.nev, (o) => o.kod], ["asc", "asc"]);
    this.allCompanies = orderBy(this.allCompanies, [(o) => o.nev, (o) => o.kod], ["asc", "asc"]);
  }

  private onUserCompanyDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.CompanyLoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }

  get canAssignCompanies() {
    return this.accountService.currentUser.isAdministrator;
  }

  get canViewAllGroups() {
    return this.accountService.userHasPermission(Permission.viewGroupsPermission);
  }

  get canAssignGroups() {
    return this.accountService.userHasPermission(Permission.assignGroupsPermission);
  }
}
