<div class="container">
    <header class="pageHeader">
        <h3><i class="fa fa-exclamation-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.Legal' | translate}}</h3>
    </header>

    <div [@fadeInOut] class="vertical-center-flex">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <p>
                    A rendszer használatával, illetve az alkalmazás telepítésével Ön elfogadja általános szerződési feltételeinket.
                    Kérjük, hogy amennyiben felhasználója kíván lenni a weblapunkon kínált szolgáltatásoknak, 
                    figyelmesen olvassa el az ÁSZF-et és kizárólag abban az esetben vegye igénybe szolgáltatásainkat, 
                    amennyiben minden pontjával egyetért, és azokat kötelező érvényűnek tekinti magára nézve.
                </p>
                <h5><b><a href="https://drive.google.com/open?id=1XtBM9nF7qiF0loFEzcD0lf3NGaO_tlVF" target="_blank">A Novaplan Szoftver Kft Általános Szerződési Feltételei</a></b></h5>
                <p>
                    Személyes adatainak védelmét nagyon komolyan vesszük. Adatvédelmi és adatkezelési tájékoztatónk bemutatja a cégünk
                    által alkalmazott adatvédelmi és adatkezelési elveket:
                </p>
                <h5><b><a href="https://drive.google.com/open?id=1Xtq0yya25bBts3UkcBGPAakxavjFYLUR" target="_blank">Adatvédelmi és adatkezelési tájékoztató</a></b></h5>
                <h4>Lépjen kapcsolatba velünk!</h4>
                <p>
                    Ha bármilyen adatkezeléssel kapcsolatos kérdése vagy észrevétele van, elér minket az
                    <a href="mailto:info@novaplan-soft.com?Subject=KUR adatvédelem">info@novaplan-soft.com </a> címen.
                </p>
            </div>
        </div>        
    </div>
</div>