import { Component, OnInit, OnDestroy, Input } from "@angular/core";

import { AlertService, MessageSeverity, DialogType } from "../../services/alert.service";
import { AuthService } from "../../services/auth.service";
import { ConfigurationService } from "../../services/configuration.service";
import { Utilities } from "../../services/utilities";
import { UserSignUp } from "../../models/user-sign-up.model";

@Component({
    selector: "app-sign-up",
    styleUrls: ["./sign-up.component.scss"],
    templateUrl: "./sign-up.component.html",
})

export class SignUpComponent implements OnInit, OnDestroy {
    public userSignUp = new UserSignUp();
    public isLoading = false;
    public formResetToggle = true;
    public modalClosedCallback: () => void;
    public loginStatusSubscription: any;

    @Input()
    public isModal = false;

    constructor(private alertService: AlertService, private authService: AuthService, private configurations: ConfigurationService) {
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        if (this.loginStatusSubscription) {
            this.loginStatusSubscription.unsubscribe();
        }
    }

    public showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }

    public closeModal() {
        if (this.modalClosedCallback) {
            this.modalClosedCallback();
        }
    }

    public signUp() {
        this.isLoading = true;
        this.alertService.startLoadingMessage("", "Regisztráció...");

        this.authService.signUp(this.userSignUp)
            .subscribe(
            () => {
                setTimeout(() => {
                    this.alertService.stopLoadingMessage();
                    this.isLoading = false;
                    this.reset();

                    if (!this.isModal) {
                        this.alertService.showMessage("Sikeres regisztráció!");
                        this.authService.gotoPage("login");
                    } else {
                        this.alertService.showMessage("Sikeres regisztráció!");
                        this.closeModal();
                    }
                }, 500);
            },
            (error) => {
                this.alertService.stopLoadingMessage();

                if (Utilities.checkNoNetwork(error)) {
                    this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
                } else {
                    const errorMessage = Utilities.getHttpResponseMessage(error);

                  if (errorMessage) {
                        this.alertService.showStickyMessage("Sikertelen regisztráció", errorMessage.join(), MessageSeverity.error, error);
                    } else {
                        this.alertService.showStickyMessage("Sikertelen regisztráció", "Hiba lépett fel a regisztráció során!\n" + error.statusText || error.status, MessageSeverity.error, error);
                    }
                }

                setTimeout(() => {
                    this.isLoading = false;
                }, 500);
            });
    }

    public reset() {
        this.formResetToggle = false;

        setTimeout(() => {
            this.formResetToggle = true;
        });
    }
}
