<div class="container">
  <header class="pageHeader">
    <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.AccountingManual' | translate}}</h3>
  </header>

  <div [@fadeInOut]>
    <div class="row">
      <div class="col-md-11 offset-md-1">
        <p>
          Kettős könyvvitel modulunk részletes leírásához kattintson a fejezetcímekre és töltse le a dokumentációt!
        </p>
        <h5><b><a href="https://drive.google.com/open?id=1XuTC8SE9og7gq19BAqTheK1fad9RBLsp" target="_blank">Tartalomjegyzék</a></b></h5>
        <ul style="list-style-type:decimal">
          <li style="font-weight:bold">
            <a href="https://drive.google.com/open?id=1Y0t5Fl08K61jQgRZtL5KN5hcCR3EMjM1" target="_blank">Novaplan-KUR Integrált komplex ügyviteli
              rendszer</a>
            <p style="font-weight:normal">
              Novaplan KUR ügyviteli rendszer felépítése * A KUR keretrendszer szolgáltatásai *
              A kettős könyvvitel és a kapcsolódó modulok * A KUR-KK kettős könyvvitel modul szolgáltatásai *
              A KUR-KK/PE házi pénztár modul * A KUR-KK/TE tárgyi eszköz nyilvántartás modul
            </p>
          </li>
          <li style="font-weight:bold">
            <a href="https://drive.google.com/open?id=1Y28HrF2vFwdDj0UbrI-p8N4fkpnrj9yB" target="_blank">A program használata</a>
            <p style="font-weight:normal">
              Belépés a programba * KUR fő képernyő és cég kiválasztása * Ablakok általános használata *
              Cégek és felhasználók adminisztrációja * Felhasználói jogosultságok (role-ok)
            </p>
          </li>
          <li style="font-weight:bold">
            <a href="https://drive.google.com/open?id=19j1RnydazlWy2w_xyI9_F0GG85EN3n_coRnTBtT11pc" target="_blank">Alap törzsek</a>
            <p style="font-weight:normal">
              Alap törzsek kezelése * A kettős könyvvitelben használt általános törzs adatok * Partner csoportok és partner típusok *
              Beosztások és egységek * Partnerek adatai * Új partner felvétele és a partnerekhez felvihető alap adatok *
              Partner cím adatok * Partner kapcsolat adatok * Partnerhez rögzíthető további adatok * Céghez kapcsolódó járművek *
              Projektek elszámolása és több dimenziójú csoportosítása * Pénznemek és árfolyamok * Üzleti évek beállítása *
              A kettős könyvvitel modul saját törzs adatai * A számlatükör és a folyószámlák * Áfakulcsok * Kontírozási minták
            </p>
          </li>
          <li style="font-weight:bold">
            <a href="https://drive.google.com/open?id=1YASC_4ne9cWZWJ46eHLHBJCxreFVjuFT" target="_blank">Könyvelés</a>
            <p style="font-weight:normal">
              Kettős könyvvitel főképernyő * Könyvelés indítása * Könyvelés ablak és a működéséhez kapcsolódó beállítások *
              Bizonylat fejléc adatai * Bizonylat tételek * Pénzforgalmi áfa elszámolás * Kiegyenlítések * Feladások *
              A feladáshoz kapcsolódó forrásállomány paramétereinek beállítása * Mezők összerendelése *
              Importált bizonylatok sorszámozása * Speciális konverziók beállítása * Partnerek adatainak importálása *
              Bankkivonatok automatikus könyvelése * Zárások és nyitások * Évvégi zárás * Év eleji nyitás
            </p>
          </li>
          <li style="font-weight:bold">
            <a href="https://drive.google.com/open?id=1YGb4hDOCfuGldNOwpQ1GLxYSFunoKURE" target="_blank">Lekérdezések</a>
            <p style="font-weight:normal">
              Számlaforgalom * Bizonylat adatai * Főkönyvi kivonat * Mérleg * Naplók * Folyószámla listák * Levelek nyomtatása * Áfa nyilvántartás *
              Kiegyenlítések * Kiegyenlített tételek legyűjtése * Kiegyenlített tételek ellenőrzése *
              Összevezethető tételek keresése * Költségfelosztó ív * Költség tábla
            </p>
          </li>
        </ul>
        <p>
          Ha ki szeretné próbálni alkalmazásunkat, akkor
          <a href="javascript:;" routerLink="/signup">regisztráljon</a> rendszerünkbe!
        </p>
      </div>
    </div>
  </div>
</div>
