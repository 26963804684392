<div class="container">
  <header class="pageHeader">
    <h3><i class="fa fa-exclamation-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.ConfirmEmail' | translate}}</h3>
  </header>

  <div [@fadeInOut] class="vertical-center-flex">
    <p *ngIf="!isSuccessfulEmailConfirmation">
      Regisztrációs email megerősítése folyamatban...
    </p>
    <div class="center-block" *ngIf="isSuccessfulEmailConfirmation">
      <p>
        Sikeres volt az email megerősítése!
      </p>
      <div><a class="btn btn-primary" routerLink="/"><i class='fa fa-home'></i> {{'notFound.backToHome' | translate}}</a></div>
    </div>
  </div>
</div>
