<div class="container about-page">
  <header class="pageHeader">
    <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.About' | translate}}</h3>
  </header>

  <div class="reduced-font" [@fadeInOut]>
    <div class="row">
      <div class="col-md-6">
        <h5><b>A KUR projekt</b></h5>
        <p>
          A novaplan-KUR (Komplex Ügyviteli Rendszer) egy integrált ügyviteli rendszer. A projektet 2015-ben indítottuk
          el.
          Célunk, hogy önálló ügyviteli szoftvereinket a funkciók újragondolása után, modern architekturális alapon egy
          komplex
          ügyviteli rendszerré alakítsuk.
        </p>
        <p>
          Új rendszerünk felhő alapú adattárolással kínálja az ügyviteli szoftvereinkben már megszokott funkciókat.
          A fejlesztés során igyekszünk olyan kényelmi funkciókkal kibővíteni az alkalmazásokat, amelyek tovább növelik
          a hatékonyságot. A funkciók kidolgozásakor ügyelünk arra, hogy a rendszer átlátható és könnyen használható
          legyen.
        </p>
      </div>
      <div class="col-md-6">
        <h5><b>Biztonság</b></h5>
        <p>
          A modern kor követelményeinek olyan rendszerek felelnek meg, amelyek magas szintű védelmet nyújtanak
          az adatok továbbításakor és tárolásakor. A felhő alapú szolgáltatás előnye, hogy a felhasználó helyett a
          szolgáltató gondoskodik az adatok biztonságáról. A rendszerünk mögött álló
          Microsoft Azure technológia segítségével biztosítani tudjuk, hogy az alkalmazás Internetes kapcsolat
          segítségével bármikor és bárhonnan használható.
          Az adatátvitel titkosított, biztonságos csatornán keresztül történik.
        <p>
        <h5><b>Használhatóság</b></h5>
        <p>
          A magas szintű biztonság mellett a legfontosabb a számunkra az, hogy felhasználóink könnyen eligazodjanak
          alkalmazásunkban. Arra törekszünk, hogy a komplex rendszerbe integrált bonyolult funkciókhoz
          egy logikusan felépített, átlátható felhasználói felületet alakítsunk ki.
          A felhasználói élmény növeléséhez szívesen vesszük az Önök visszajelzéseit, javaslatait!
        </p>
        <p>
          Az ügyviteli rendszerünk által nyújtott szolgáltatásokról a Rendszer menüben elérhető részletes
          programleírások
          segítségével tájékozódhatnak.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h5><b>A fejlesztés aktuális állapota</b></h5>
        <p>
          Web szerverünkön a KUR keretrendszer és a kettős könyvvitel modul már üzemel.
          Rövidesen elérhetővé tesszük a demo rendszert is, amelyben régi felhasználóink és
          az új érdeklődök is regisztrálni tudnak, hogy kipróbálhassák az alkalmazást.
        </p>
      </div>
      <div class="col-md-6">
        <h5><b>A KUR projekt fejlesztésének ütemterve</b></h5>
        <p>
          2017. kettős könyvvitel alkalmazás éles üzeme
        </p>
        <p>
          2023. speciális lakásszövetkezeti és társasházi analitikai modul
        </p>
        <p>
          202X. készletnyilvántartás és számlázás (munkalapok és menetlevelek nyilvántartása)
        </p>
        <p>
          202X. bérszámfejtés
        </p>
      </div>
    </div>
    <div class="row bg-grey">
      <div class="col-lg-4 pr-lg-5">
        <span class="fa fa-globe logo"></span>
      </div>
      <div class="col-lg-4">
        <h5><b>Alkalmazott technológiák</b></h5>
        <ul style="list-style-type:disc">
          <li>Adatbázis: MS SQL szerver 2019</li>
          <li>Szerver kiszolgáló: Azure Cloud</li>
          <li>Kommunikáció: Web service</li>
          <li>Biztonság: SSL tanúsítvány</li>
        </ul>
      </div>
      <div class="col-lg-4">
        <h5><b>Rendszerkövetelmények</b></h5>
        <ul style="list-style-type:disc">
          <li>Adatbázis: MS SQL szerver 2008 R2 -től</li>
          <li>Szerver kiszolgáló: Windows 7 SP1, Windows 8.1, Windows Server 2012 R2</li>
          <li>Kliens: Webes alkalmazás esetén - Chrome, FireFox, Opera, Edge, WPF - Windows 7-től</li>
          <li>Biztonság: SSL tanúsítvány</li>
        </ul>
      </div>
    </div>
    <div class="row bg-grey">
      <div class="col-lg-6">
        <h3>Lépjen kapcsolatba velünk!</h3>
        <p> Novaplan Szoftver Kft * 5711 Gyula, Szarkaláb utca 17. * 1214 Budapest, Kossuth Lajos utca 112.</p>
        <p>
          Az <a href="mailto:info@novaplan-soft.com?Subject=Kur rendszer">info@novaplan-soft.com </a> címen elér minket.
        </p>
      </div>
    </div>
  </div>
</div>
