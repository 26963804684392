import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";

import { fadeInOut } from "../../services/animations";
import { BootstrapTabDirective } from "../../directives/bootstrap-tab.directive";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { Permission } from "../../models/permission.model";
import { Utilities } from 'src/app/services/utilities';

@Component({
  animations: [fadeInOut],
  selector: "admin",
  styleUrls: ["./admin.component.scss"],
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit, OnDestroy {
  public isCompaniesActivated = true;
  public isNavStatusActivated = false;
  public isUsersActivated = false;
  public isGroupsActivated = false;
  public isCirclesActivated = false;

  public fragmentSubscription: any;

  public readonly companiesTab = "companies";
  public readonly navStatusTab = "nav-status";
  public readonly usersTab = "users";
  public readonly groupsTab = "groups";
  public readonly circlesTab = "circles";

  @ViewChild("tab", { static: true })
  public tab: BootstrapTabDirective;

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService, private authService: AuthService) {
  }

  public ngOnInit() {
    this.fragmentSubscription = this.route.fragment.subscribe((anchor) => this.showContent(anchor));
  }

  public ngOnDestroy() {
    this.fragmentSubscription.unsubscribe();
  }

  public handleLanguageChangedEvent() {
  }

  public showContent(anchor: string) {
    if (anchor) {
      anchor = anchor.toLowerCase();
    }

    if ((this.isFragmentEquals(anchor, this.navStatusTab) && !this.canViewNavStatus) ||
      (this.isFragmentEquals(anchor, this.usersTab) && !this.canViewUsers) ||
      (this.isFragmentEquals(anchor, this.groupsTab) && !this.canViewGroups) ||
      (this.isFragmentEquals(anchor, this.circlesTab) && !this.canViewCircles)) {
      return;
    }

    this.tab.show(`#${anchor || this.companiesTab}Tab`);
  }

  public onShowTab(event) {
    const activeTab = event.target.hash.split("#", 2).pop();

    this.isCompaniesActivated = activeTab === this.companiesTab;
    this.isNavStatusActivated = activeTab === this.navStatusTab;
    this.isUsersActivated = activeTab === this.usersTab;
    this.isGroupsActivated = activeTab === this.groupsTab;
    this.isCirclesActivated = activeTab === this.circlesTab;

    this.router.navigate([], { fragment: activeTab });
  }

  private isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = "";
    }

    if (fragment2 == null) {
      fragment2 = "";
    }

    return fragment1.toLowerCase() === fragment2.toLowerCase();
  }

  get canViewNavStatus() {
    return Utilities.userHasRole(this.authService.currentUser, "NavOnline");
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewGroups() {
    return this.accountService.userHasPermission(Permission.viewGroupsPermission);
  }

  get canViewCircles() {
    if (this.authService.currentUser) {
      return this.authService.currentUser.isSuperAdministrator;
    }
    return false;
  }
}
