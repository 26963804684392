import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"]
})
export class SearchBoxComponent {
  @Input()
  public placeholder: string = "Keresés...";

  @Output()
  public searchChange = new EventEmitter<string>();

  @ViewChild("searchInput", { static: true })
  public searchInput: ElementRef;

  public onValueChange(value: string) {
    setTimeout(() => this.searchChange.emit(value));
  }

  public clear() {
    this.searchInput.nativeElement.value = "";
    this.onValueChange(this.searchInput.nativeElement.value);
  }
}
