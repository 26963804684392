import { Component, OnInit, ViewChild, Input } from "@angular/core";

import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { MasterDataService } from "../../services/master-data.service";
import { Utilities } from "../../services/utilities";
import { Company } from "../../models/company.model";
import { CompanyAddress } from "../../models/company-address.model";

@Component({
  selector: "company-info",
  templateUrl: "./company-info.component.html",
  styleUrls: ["./company-info.component.scss"]
})

export class CompanyInfoComponent implements OnInit {

  public formResetToggle = true;

  public changesSavedCallback: (company: Company) => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  public isViewOnly: boolean;

  private isNewCompany = false;
  public isSaving = false;
  private editingCompanyName: string;
  public uniqueId: string = Utilities.uniqueId();
  public company: Company = new Company();

  @ViewChild("f", { static: false })
  private form;

  // ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild("kod", { static: false })
  private kod;

  @ViewChild("nev", { static: false })
  private nev;

  @ViewChild("rovidNev", { static: false })
  private rovidNev;

  @ViewChild("adoSzamHu", { static: false })
  private adoSzamHu;

  @ViewChild("adoSzamEu", { static: false })
  private adoSzamEu;

  @ViewChild("adoAzon", { static: false })
  private adoAzon;

  @ViewChild("orszagKod", { static: false })
  private orszagKod;

  @ViewChild("iranyitoSzam", { static: false })
  private iranyitoSzam;

  @ViewChild("telepules", { static: false })
  private telepules;

  @ViewChild("kozteruletNeve", { static: false })
  private kozteruletNeve;

  @ViewChild("kozteruletJellege", { static: false })
  private kozteruletJellege;

  @ViewChild("hazszam", { static: false })
  private hazszam;

  @ViewChild("aktualisEv", { static: false })
  private aktualisEv;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private masterDataService: MasterDataService) {
  }

  public ngOnInit() {

  }

  public editCompany(company: Company) {
    if (company) {
      this.isNewCompany = false;
      this.masterDataService.companyAddress(company.id)
        .subscribe((companyAddress) => this.onCompanyAddressDataLoadSuccessful(companyAddress), (error) => this.onCompanyAddressDataLoadFailed(error));

      this.editingCompanyName = company.nev;
      this.company = new Company();
      Object.assign(this.company, company);
      this.edit();

      return this.company;
    } else {
      return this.newCompany();
    }
  }

  public newCompany() {
    this.isNewCompany = true;

    this.editingCompanyName = null;
    this.company = new Company();
    this.edit();

    return this.company;
  }

  public resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  private edit() {
    if (!this.company) {
      this.company = new Company();
    }
  }

  public save() {
    this.isSaving = true;
    this.alertService.startLoadingMessage(this.translation("backgroundMessage.Saving"));
    const companyAddress = new CompanyAddress();
    Object.assign(companyAddress, this.company);

    if (this.isNewCompany) {
      this.masterDataService.createCompany(this.company).subscribe((companyId) => {
        this.company.id = companyId;
        companyAddress.cegId = companyId;
        this.masterDataService.createCompanyAddress(companyAddress).subscribe((succesful) => this.saveSuccessHelper(), (error) => this.saveFailedHelper(error));
      }, (error) => this.saveFailedHelper(error));
    } else {
      companyAddress.cegId = this.company.id;
      companyAddress.id = (this.company as any).cimId;
      this.masterDataService.updateCompany(this.company, companyAddress).subscribe((succesful) => this.saveSuccessHelper(), (error) => this.saveFailedHelper(error));
    }
  }

  private saveSuccessHelper() {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewCompany) {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.company.nev} ${this.translation("serviceMessage.CreateSuccess")}`, MessageSeverity.success);
    } else {
      this.alertService.showMessage(this.translation("serviceMessage.Success"), `${this.company.nev} ${this.translation("serviceMessage.UpdateSuccess")}`, MessageSeverity.success);
    }

    if (this.changesSavedCallback) {
      this.changesSavedCallback(this.company);
    }

    this.resetForm();
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    const errorMessage = Utilities.getHttpResponseMessage(error);
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), this.translation("serviceMessage.UpdateError"), MessageSeverity.error, errorMessage);
    this.alertService.showStickyMessage(errorMessage, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  public cancel() {
    this.company = new Company();

    this.resetForm();

    this.alertService.showMessage(this.translation("serviceMessage.Cancelled"), this.translation("serviceMessage.TaskCancelled"), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  private onCompanyAddressDataLoadSuccessful(companyAddress: CompanyAddress) {
    this.alertService.stopLoadingMessage();
    let clonedCompanyAddress = this.addressToCompanyFactory();
    Object.assign(clonedCompanyAddress, companyAddress);
    clonedCompanyAddress.cimId = companyAddress.id;
    clonedCompanyAddress.id = this.company.id;
    Object.assign(this.company, clonedCompanyAddress);
  }

  private addressToCompanyFactory(): CompanyAddress & { cimId: number } {
    return new CompanyAddress() as CompanyAddress & { cimId: number };
  }

  private onCompanyAddressDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(this.translation("serviceMessage.Failure"), `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);
  }

  public showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  private translation(key: string): any {
    return this.translationService.getTranslation(key);
  }
}
