export class User {
    constructor(id?: number, userName?: string, fullName?: string,
                email?: string, jobTitle?: string, phoneNumber?: string,
                isSuperAdministrator?: boolean, isAdministrator?: boolean,
                roles?: string[]) {
        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.jobTitle = jobTitle;
        this.phoneNumber = phoneNumber;
        this.isSuperAdministrator = isSuperAdministrator;
        this.isAdministrator = isAdministrator;
        this.roles = roles;
    }

    get friendlyName(): string {
        let name = this.fullName || this.userName;

        if (this.jobTitle) {
            name = this.jobTitle + " " + name;
        }

        return name;
    }

    public id: number;
    public userName: string;
    public fullName: string;
    public firstName: string;
    public lastName: string;
    public circleName: string;
    public email: string;
    public jobTitle: string;
    public phoneNumber: string;
    public isEnabled: boolean;
    public isLockedOut: boolean;
    public isSuperAdministrator: boolean;
    public isAdministrator: boolean;
    public groups: string[]; // login doesn't fill it!!
    public roles: string[];
}
