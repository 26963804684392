import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, Input } from "@angular/core";

import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { NotificationService } from "../../services/notification.service";
import { AccountService } from "../../services/account.service";
import { Permission } from "../../models/permission.model";
import { Utilities } from "../../services/utilities";
import { Notification } from "../../models/notification.model";

@Component({
  selector: "notifications-viewer",
  templateUrl: "./notifications-viewer.component.html",
  styleUrls: ["./notifications-viewer.component.scss"]
})
export class NotificationsViewerComponent implements OnInit, OnDestroy {
  public columns: any[] = [];
  public rows: Notification[] = [];
  public loadingIndicator: boolean;

  public dataLoadingConsecutiveFailures = 0;
  public dataLoadingSubscription: any;

  @Input()
  public isViewOnly: boolean;

  @Input()
  public verticalScrollbar: boolean = false;

  @ViewChild("statusHeaderTemplate", { static: true })
  public statusHeaderTemplate: TemplateRef<any>;

  @ViewChild("statusTemplate", { static: true })
  public statusTemplate: TemplateRef<any>;

  @ViewChild("dateTemplate", { static: true })
  public dateTemplate: TemplateRef<any>;

  @ViewChild("contentHeaderTemplate", { static: true })
  public contentHeaderTemplate: TemplateRef<any>;

  @ViewChild("contenBodytTemplate", { static: true })
  public contenBodytTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  public actionsTemplate: TemplateRef<any>;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private notificationService: NotificationService) {
  }

  public ngOnInit() {
    if (this.isViewOnly) {
      this.columns = [
        { prop: "date", cellTemplate: this.dateTemplate, width: 100, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
        { prop: "header", cellTemplate: this.contentHeaderTemplate, width: 200, resizeable: false, sortable: false, draggable: false },
      ];
    } else {
      const gT = (key: string) => this.translationService.getTranslation(key);

      this.columns = [
        { prop: "", name: "", width: 10, headerTemplate: this.statusHeaderTemplate, cellTemplate: this.statusTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
        { prop: "date", name: gT("notifications.Date"), cellTemplate: this.dateTemplate, width: 30 },
        { prop: "body", name: gT("notifications.Notification"), cellTemplate: this.contenBodytTemplate, width: 500 },
        { name: "", width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
      ];
    }

    this.initDataLoading();
  }

  public ngOnDestroy() {
    if (this.dataLoadingSubscription) {
      this.dataLoadingSubscription.unsubscribe();
    }
  }

  public initDataLoading() {
    if (this.isViewOnly && this.notificationService.recentNotifications) {
      this.rows = this.processResults(this.notificationService.recentNotifications);
      return;
    }

    this.loadingIndicator = true;

    const dataLoadTask = this.isViewOnly ? this.notificationService.getNewNotifications() : this.notificationService.getNewNotificationsPeriodically();

    this.dataLoadingSubscription = dataLoadTask
      .subscribe((notifications) => {
        this.loadingIndicator = false;
        this.dataLoadingConsecutiveFailures = 0;

        this.rows = this.processResults(notifications);
      },
        (error) => {
          this.loadingIndicator = false;

          this.alertService.showMessage("Hiba történt.", "Az új értesítések letöltése nem sikerült!", MessageSeverity.warn);
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailures++ < 5) {
            setTimeout(() => this.initDataLoading(), 5000);
          } else {
            this.alertService.showStickyMessage("Hiba történt.", "Az új értesítések letöltése nem sikerült!", MessageSeverity.error);
          }
        });

    if (this.isViewOnly) {
      this.dataLoadingSubscription = null;
    }
  }

  public getPrintedDate(value: Date) {
    if (value) {
      return Utilities.printTimeOnly(value) + " on " + Utilities.printDateOnly(value);
    }
  }

  public deleteNotification(row: Notification) {
    this.alertService.showDialog('Biztos törölni akarja az értesítést? \"' + row.header + '\"?', DialogType.confirm, () => this.deleteNotificationHelper(row));
  }

  public deleteNotificationHelper(row: Notification) {
    this.alertService.startLoadingMessage("Törlés...");
    this.loadingIndicator = true;

    this.notificationService.deleteNotification(row)
      .subscribe((results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rows = this.rows.filter((item) => item.id !== row.id);
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Hiba történt.", `Az értesítés törlése nem sikerült.\r\nHiba üzenet: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  public togglePin(row: Notification) {
    const pin = !row.isPinned;
    const opText = pin ? "Pinning" : "Unpinning";

    this.alertService.startLoadingMessage(opText + "...");
    this.loadingIndicator = true;

    this.notificationService.pinUnpinNotification(row, pin)
      .subscribe((results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        row.isPinned = pin;
      },
        (error) => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(opText + " Error", `An error occured whilst ${opText} the notification.\r\nError: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  get canManageNotifications() {
    return this.accountService.userHasPermission(Permission.manageGroupsPermission); // Todo: Consider creating separate permission for notifications
  }

  private processResults(notifications: Notification[]) {
    if (this.isViewOnly) {
      notifications.sort((a, b) => {
        return b.date.valueOf() - a.date.valueOf();
      });
    }

    return notifications;
  }
}
