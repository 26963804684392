import { Injectable, Injector } from "@angular/core";
import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { EndpointBase } from "./endpoint-base.service";
import { ConfigurationService } from "./configuration.service";
import { Company } from "./../models/company.model";
import { CompanyAddress } from "./../models/company-address.model";
import { NavOnline } from "../models/nav-online.model";

@Injectable()
export class MasterDataEndpoint extends EndpointBase {
  private readonly _companiesUrl: string = "/api/cegek";
  private readonly _companyAddressUrl: string = "/api/ceg/cimek";
  private readonly _companyDeleteUrl: string = "/api/cegek";
  private readonly _currentCompanyUrl: string = "/api/cegek";
  private readonly _updateCompanyUrl: string = "/api/cegek";
  private readonly _createCompanyUrl: string = "/api/cegek";
  private readonly _updateCompanyAddressUrl: string = "/api/ceg/cimek";
  private readonly _createCompanyAddressUrl: string = "/api/ceg/cimek";
  private readonly _usersUrl: string = "/api/users";
  private readonly _navOnlineUrl: string = "/api/navOnline";
  private readonly dateFormat: string = "yyyy-MM-dd";

  get companiesUrl() { return this.configurations.baseUrl + this._companiesUrl; }
  get companyAddressCompanyUrl() { return this.configurations.baseUrl + this._companyAddressUrl; }
  get companyDeleteUrl() { return this.configurations.baseUrl + this._companyDeleteUrl; }
  get currentCompanyUrl() { return this.configurations.baseUrl + this._currentCompanyUrl; }
  get updateCompanyUrl() { return this.configurations.baseUrl + this._updateCompanyUrl; }
  get createCompanyUrl() { return this.configurations.baseUrl + this._createCompanyUrl; }
  get updateCompanyAddressUrl() { return this.configurations.baseUrl + this._updateCompanyAddressUrl; }
  get createCompanyAddressUrl() { return this.configurations.baseUrl + this._createCompanyAddressUrl; }
  get getUserAvailableCompaniesUrl() { return this.configurations.baseUrl + this._usersUrl; }
  get getUserCompaniesUrl() { return this.configurations.baseUrl + this._usersUrl; }
  get updateUserCompaniesUrl() { return this.configurations.baseUrl + this._usersUrl; }
  get navOnlineUrl() { return this.configurations.baseUrl + this._navOnlineUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, private datePipe: DatePipe) {
    super(http, configurations, injector);
  }

  public getNavStatusResendEndpoint<T>(navOnlineStatusId: number): Observable<T> {
    const endpointUrl = `${this.navOnlineUrl}/${navOnlineStatusId}/resend`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNavStatusResendEndpoint(navOnlineStatusId));
      }));
  }

  public getNavOnlineByCompanyIdEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.navOnlineUrl}/${companyId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getNavOnlineByCompanyIdEndpoint(companyId));
      }));
  }

  public getNavStatusByCompanyIdEndpoint<T>(companyId: number, dateFrom?: Date, dateTo?: Date): Observable<T> {
    const endpointUrl = dateFrom && dateTo ?
      `${this.navOnlineUrl}/${companyId}/statuses/${this.datePipe.transform(dateFrom, this.dateFormat)}/${this.datePipe.transform(dateTo, this.dateFormat)}` :
      `${this.navOnlineUrl}/${companyId}/statuses`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getNavStatusByCompanyIdEndpoint(companyId, dateFrom, dateTo));
      }));
  }

  public getNavStatusEndpoint<T>(dateFrom: Date, dateTo: Date): Observable<T> {
    const endpointUrl = `${this.navOnlineUrl}/statuses/${this.datePipe.transform(dateFrom, this.dateFormat)}/${this.datePipe.transform(dateTo, this.dateFormat)}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getNavStatusEndpoint(dateFrom, dateTo));
      }));
  }

  public createOrUpdateNavOnlineEndpoint<T>(navOnline: NavOnline): Observable<T> {
    return this.http.post<T>(this.navOnlineUrl, JSON.stringify(navOnline), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.createOrUpdateNavOnlineEndpoint(navOnline));
      }));
  }

  public getCompaniesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.companiesUrl}/${page}/${pageSize}` : this.companiesUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getCompaniesEndpoint(page, pageSize));
      }));
  }

  public getCompanyAddressEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/${companyId}/cim`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getCompanyAddressEndpoint(companyId));
      }));
  }

  public getDeleteCompanyEndpoint(companyId: number) {
    const endpointUrl = `${this.companyDeleteUrl}/${companyId}`;

    return this.http.delete(endpointUrl, this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getDeleteCompanyEndpoint(companyId));
      }));
  }

  public getCurrentCompanyEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.currentCompanyUrl}/${companyId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getCurrentCompanyEndpoint(companyId));
      }));
  }

  public getUpdateCompanyEndpoint(company: Company) {
    return this.http.put(this.updateCompanyUrl, JSON.stringify(company), this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateCompanyEndpoint(company));
      }));
  }

  public getCreateCompanyEndpoint<T>(company: Company): Observable<T> {
    return this.http.post<T>(this.createCompanyUrl, JSON.stringify(company), this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getCreateCompanyEndpoint(company));
      }));
  }

  public getUpdateCompanyAddressEndpoint(companyAddress: CompanyAddress) {
    return this.http.put(this.updateCompanyAddressUrl, JSON.stringify(companyAddress), this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateCompanyAddressEndpoint(companyAddress));
      }));
  }

  public getCreateCompanyAddressEndpoint(companyAddress: CompanyAddress) {
    return this.http.post(this.createCompanyAddressUrl, JSON.stringify(companyAddress), this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getCreateCompanyAddressEndpoint(companyAddress));
      }));
  }

  public getUserAvaliableCompaniesEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.getUserAvailableCompaniesUrl}/${userId}/companies`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUserAvaliableCompaniesEndpoint(userId));
      }));
  }

  public getUserCompaniesEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.getUserCompaniesUrl}/${userId}/companyIds`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError((error) => {
        return this.handleError(error, () => this.getUserCompaniesEndpoint(userId));
      }));
  }

  public getUpdateUserCompaniesEndpoint(userId: number, companyIds: number[]) {
    const endpointUrl = `${this.getUserCompaniesUrl}/${userId}/companies`;

    return this.http.put(endpointUrl, JSON.stringify({ userId: userId, cegIds: companyIds }), this.requestHeaders).pipe(
      catchError((error) => {
        return this.handleError(error, () => this.getUpdateUserCompaniesEndpoint(userId, companyIds));
      }));
  }
}
