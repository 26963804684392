import { Component, OnInit, OnDestroy, Input } from "@angular/core";

import { AlertService, MessageSeverity, DialogType } from "../../services/alert.service";
import { AuthService } from "../../services/auth.service";
import { ConfigurationService } from "../../services/configuration.service";
import { Utilities } from "../../services/utilities";
import { UserLogin } from "../../models/user-login.model";
import { AppTranslationService } from "src/app/services/app-translation.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})

export class LoginComponent implements OnInit, OnDestroy {
    public userLogin = new UserLogin();
    public isLoading = false;
    public formResetToggle = true;
    public modalClosedCallback: () => void;
    public loginStatusSubscription: any;

    @Input()
    public isModal = false;

  constructor(private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private configurations: ConfigurationService) {
    }

    public ngOnInit() {
        this.userLogin.rememberMe = this.authService.rememberMe;

        if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
        } else {
            this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe((isLoggedIn) => {
                if (this.getShouldRedirect()) {
                    this.authService.redirectLoginUser();
                }
            });
        }
    }

    public ngOnDestroy() {
        if (this.loginStatusSubscription) {
            this.loginStatusSubscription.unsubscribe();
        }
    }

    public getShouldRedirect() {
        return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
    }

    public showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }

    public closeModal() {
        if (this.modalClosedCallback) {
            this.modalClosedCallback();
        }
    }

    public login() {
        this.isLoading = true;
        this.alertService.startLoadingMessage("", "Bejelentkezés folyamatban...");

        this.authService.login(this.userLogin.userName, this.userLogin.password, this.userLogin.rememberMe)
            .subscribe(
            (user) => {
                setTimeout(() => {
                    this.alertService.stopLoadingMessage();
                    this.isLoading = false;
                    this.reset();

                    if (!this.isModal) {
                        this.alertService.showMessage("Bejelentkezve", `Üdvözlet ${this.userLogin.userName}!`, MessageSeverity.success);
                    } else {
                        this.alertService.showMessage("Bejelentkezve", `${this.userLogin.userName} munkamenete visszaállítva.`, MessageSeverity.success);
                        setTimeout(() => {
                            this.alertService.showStickyMessage("Munkamenet visszaállítva", "Próbálja újra végrehajtani a félbeszakadt műveletet!", MessageSeverity.default);
                        }, 500);

                        this.closeModal();
                    }
                }, 500);
            },
            (error) => {
                this.alertService.stopLoadingMessage();

                if (Utilities.checkNoNetwork(error)) {
                    this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
                    this.offerAlternateHost();
                } else {
                    const errorMessage = Utilities.getHttpResponseMessage(error);

                    if (errorMessage) {
                      this.alertService.showStickyMessage("Sikertelen bejelentkezés", `${this.translation("serviceMessage.LoadError")}"${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
                    } else {
                        this.alertService.showStickyMessage("Sikertelen bejelentkezés", "A bejelentkezési nem sikerült a megadott adatokkal. Próbálja újra!\n" + error.statusText || error.status, MessageSeverity.error, error);
                    }
                }

                setTimeout(() => {
                    this.isLoading = false;
                }, 500);
            });
    }

    public offerAlternateHost() {
        if (Utilities.checkIsLocalHost(location.origin) && Utilities.checkIsLocalHost(this.configurations.baseUrl)) {
            this.alertService.showDialog("Kedves fejlesztő!\nÚgy tűnik, hogy a backend Web API service nem üzemel.\n" +
                "Áttérsz az alábbi Demo API-ra?(Vagy megadhatsz másikat.)",
                DialogType.prompt,
                (value: string) => {
                    this.configurations.baseUrl = value;
                    this.alertService.showStickyMessage("Az API megváltozott.", "Web API átállítva: " + value, MessageSeverity.warn);
                },
                null,
                null,
                null,
                this.configurations.fallbackBaseUrl);
        }
    }

    public reset() {
        this.formResetToggle = false;

        setTimeout(() => {
            this.formResetToggle = true;
        });
    }

    private translation(key: string): any {
        return this.translationService.getTranslation(key);
    }
}
