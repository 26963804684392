import { Injectable, Injector, ErrorHandler } from "@angular/core";
import { AppInsightsService } from "@markpieszak/ng-application-insights";


@Injectable()
export class AppErrorHandler extends ErrorHandler {

    // private alertService: AlertService;
    private appInsightsService: AppInsightsService;

    constructor(private injector: Injector) {
        super();
    }


    public handleError(error: any) {
        debugger;
        // if (this.alertService == null) {
        //    this.alertService = this.injector.get(AlertService);
        // }
        if (this.appInsightsService == null) {
           this.appInsightsService = this.injector.get(AppInsightsService);
        }

        // this.alertService.showStickyMessage("Fatal Error!", "An unresolved error has occured. Please reload the page to correct this error", MessageSeverity.warn);
        // this.alertService.showStickyMessage("Unhandled Error", error.message || error, MessageSeverity.error, error);
        this.appInsightsService.trackException(error);

        //if (error && error === "session expired") {
        //    return;
        //}

        if (confirm("Végzetes hiba!\nMegpróbálja újratölteni a lapot?\n\Hiba: " + error.message)) {
            window.location.reload(true);
        }

        super.handleError(error);
    }
}
