<div class="container">
  <header class="pageHeader">
    <h3>
      <i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.Features' | translate}}</h3>
  </header>

  <div [@fadeInOut]>
    <div class="row">
      <div class="col-md-11 offset-md-1">
        <p>
          Integrált ügyviteli rendszerünkben egységes törzsadat-rendszer szolgálja ki az egyes modulokat. Úgy alakítottuk
          ki az adattörzseket, hogy közös adatbázisban lehessen nyilvántartani a különböző modulokhoz szükséges információkat.
          A rendszer magját képező könyvelés logikájának megfelelően az üzleti partnerek nyilvántartása elkülönül a vevők, a szállítók és egyéb
          partnerek
          nyilvántartására. Az üzleti partnerek csoportokba és típusokba sorolhatóak. Ezeket a törzseket teljesen szabadon,
          az egyedi ügyfél igényeknek megfelelően lehet kialakítani.
        </p>
        <h5>
          <b>Integrált törzsadat kezelés</b>
        </h5>
        <ul style="list-style-type:disc">
          <li>partner csoportok és tipusok, egységek és beosztások szabad beállítása;</li>
          <li>
            partnerek nyilvántartása (vevők, szállítók és egyéb partnerek):
            <ul>
              <li>tetszőleges számú címadat tárolása (székhely, telephelyek, levelezési címek, fióktelepek stb.),</li>
              <li>tetszőleges számú bankszámla adat tárolása,</li>
              <li>tetszőleges számú kapcsolat (nevek és kapcsolódó funkciók) tárolása elérhetőséggel,</li>
              <li>partner járműveinek tárolása;</li>
            </ul>
          </li>
          <li>járművek nyilvántartása (partnerenként és cégenként) - a könyvelésben a járművek költség objektumként terhelhetőek;</li>
          <li>
            4 dimenziós projekt nyilvántartás szabadon paraméterezhető projekt csoportokkal - a könyvelésben a projektek
            költség objektumként terhelhetőek.
          </li>
        </ul>
        <h5>
          <b>Webes adminisztrációs felület</b>
        </h5>
        <ul style="list-style-type:disc">
          <li>szabadon definiálható hozzáférés csoportok;</li>
          <li>felhasználók adminisztrációja;</li>
          <li>a cégekhez és funkciókhoz a hozzáféréseket rugalmasan lehet kialakítani.</li>
        </ul>
        <h5>
          <b>Kliens oldali WPF alkalmazás - könnyen használható felhasználói felület</b>
        </h5>
        <ul style="list-style-type:disc">
          <li>beviteli mezők értékeinek tárolása helyileg felhasználóhoz rendelten;</li>
          <li>adatbevitel támogatása könnyen áttekinthető legördülő listákkal;</li>
          <li>gyors keresés a listákban kód és név szerint;</li>
          <li>a lekérdezések bármilyen mező szerint sorrendbe állíthatóak;</li>
          <li>a lekérdezések és listák standard formátumú csv állományokba exportálhatóak.</li>
        </ul>
        <p>
          További információkat a keretrendszer
          <a href="https://drive.google.com/open?id=1YME8RKejahlLI4dGONHRTch6rRuq5BEW" target="_blank">felhasználói útmutatójában</a> talál.
        </p>
        <p>
          Ha ki szeretné próbálni alkalmazásunkat, akkor
          <a href="javascript:;" routerLink="/signup">regisztráljon</a> rendszerünkbe!
        </p>
      </div>
    </div>
  </div>
</div>
